import {Pagination } from "react-bootstrap"

type PaginationSettings = {
    totalPages: number,
    pageNumber: number,
    setPageNumber: Function,
}

export default function DataListPagination ({ totalPages, pageNumber, setPageNumber}: PaginationSettings) {

    const decreasePage = () => {
        if (pageNumber <= 1) {
            return;
        }
        setPageNumber(pageNumber - 1);
    };

    const increasePage = () => {
        if (pageNumber >= totalPages) {
            return;
        }
        setPageNumber(pageNumber + 1);
    };

    const paginationButtons = [];


    for (let number = 1; number <= totalPages; number++) {
        if (
            (number >= pageNumber - 3 && number < pageNumber) ||
            (number <= pageNumber + 3 && number > pageNumber) ||
            (number === pageNumber)
        ) {
            paginationButtons.push(
                <Pagination.Item
                    key={number}
                    active={number === pageNumber}
                    onClick={() => setPageNumber(number)}
                >
                {number}
                </Pagination.Item>
            );
        }
    }

    return (
        <Pagination className="mb-0" style={{padding: '10px'}}>
            <Pagination.First onClick={() => setPageNumber(1)} />
            <Pagination.Prev onClick={decreasePage} />
            {paginationButtons}
            <Pagination.Next onClick={increasePage} />
            <Pagination.Last onClick={() => setPageNumber(totalPages)} />
        </Pagination>
    )
}
