import { useLocation } from "wouter";
import { UrlPaths } from "../utilities/types";

export default function useLogout() {
  const [, setLocation] = useLocation();

  const logout = () => {
    localStorage.removeItem("role");
    localStorage.removeItem("token");

    setLocation(UrlPaths.Home);
  };

  return logout;
}
