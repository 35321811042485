import Button from "react-bootstrap/Button";

export default function RequestService() {
  return (
    <>
      <div>Request a Service</div>
      <Button>Contact an Agent</Button>
    </>
  );
}
