import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { UrlPaths } from "../../utilities/types";
import { useLocation } from "wouter";
import {
  PiUsersThreeLight,
  PiCalendarStarThin,
  PiSlidersHorizontalDuotone,
} from "react-icons/pi";
import { GrDocumentUser } from "react-icons/gr";
import { FaLocationDot } from "react-icons/fa6";
import { BsShieldFillCheck, BsFillBookmarkDashFill } from "react-icons/bs";
import { IoWallet, IoChevronForward } from "react-icons/io5";
import { TbCategory } from "react-icons/tb";
import styled from "styled-components";
import LogoSvg from "../../assets/logo trial-01.svg";

const StyledNavBar = styled(Navbar)`
  /* height: 100%; */
  /* width: fit-content; */
  /* width: 13%; */
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 5px 14px 5px 14px;
  /* flex-shrink: 0; */

  /* overflow-y: auto; */
  hr {
    margin: 10px 5px;
    align-items: center;
  }
`;

const StyledNav = styled(Nav)`
  width: 100%;
  margin-top: 4px;
  flex: 1;
`;

const StyledNavItem = styled(Nav.Item)`
  width: 100%;
  margin: auto 0;
  /* margin: 5px 0; */
`;

const StyledNavLink = styled(Nav.Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  width: 100%;
`;

const IconWrapper = styled.span`
  display: flex;
  align-items: center;
  margin-right: 10px;

  span {
    padding-left: 10px;
  }
`;

const LogoImg = styled.img`
  width: 150px;
  height: auto;
`;

const LogoContainer = styled.div`
  display: flex;
  /* justify-content: center; */
  align-items: center;
  /* padding: 10px; */
  padding-top: 2px;
`;

export default function LeftNavbar() {
  const [currentPath] = useLocation();

  const path = currentPath as UrlPaths;

  // TODO: add support for Array<UrlPaths> so I can add subpages
  const setActive = (pathToMatch: UrlPaths): boolean => pathToMatch === path;
  // TODO: overwrite active Nav.Item color styling
  return (
    <StyledNavBar>
      <div>
        <LogoContainer>
          <a href={UrlPaths.Home}>
            <LogoImg src={LogoSvg} alt="logo" />
          </a>
        </LogoContainer>
        {/* <Navbar bg="link" className="flex-sm-column"> */}
        <StyledNav className="flex-sm-column">
          <div>Home</div>
          <StyledNavItem>
            <StyledNavLink
              href={UrlPaths.Dashboard}
              active={setActive(UrlPaths.Dashboard)}
            >
              <IconWrapper>
                <TbCategory /> <span>Dashboard</span>
              </IconWrapper>
            </StyledNavLink>
          </StyledNavItem>
          <StyledNavItem>
            <StyledNavLink
              href={UrlPaths.Notifications}
              active={setActive(UrlPaths.Notifications)}
            >
              <IconWrapper>
                <PiSlidersHorizontalDuotone /> <span>Notifications</span>
              </IconWrapper>

              <IoChevronForward />
            </StyledNavLink>
          </StyledNavItem>
        </StyledNav>
        <hr />
        <StyledNav className="flex-sm-column">
          <div>Pages</div>
          <StyledNavItem>
            <StyledNavLink
              href={UrlPaths.Trusts}
              active={setActive(UrlPaths.Trusts)}
            >
              <IconWrapper>
                <GrDocumentUser />
                <span>Trusts</span>
              </IconWrapper>
              <IoChevronForward />
            </StyledNavLink>
          </StyledNavItem>
          <StyledNavItem>
            <StyledNavLink
              href={UrlPaths.Clients}
              active={setActive(UrlPaths.Clients)}
            >
              <IconWrapper>
                <PiUsersThreeLight />
                <span>Old Clients</span>
              </IconWrapper>
              <IoChevronForward />
            </StyledNavLink>
          </StyledNavItem>
          <StyledNavItem>
            <StyledNavLink
              href={UrlPaths.Appointments}
              active={setActive(UrlPaths.Appointments)}
            >
              <IconWrapper>
                <PiCalendarStarThin />
                <span>Appointments</span>
              </IconWrapper>
              <IoChevronForward />
            </StyledNavLink>
          </StyledNavItem>
          <StyledNavItem>
            <StyledNavLink
              href={UrlPaths.Calendar}
              active={setActive(UrlPaths.Calendar)}
            >
              <IconWrapper>
                <FaLocationDot />
                <span>Calendar</span>
              </IconWrapper>
              <IoChevronForward />
            </StyledNavLink>
          </StyledNavItem>
          <StyledNavItem>
            <StyledNavLink
              href={UrlPaths.Messages}
              active={setActive(UrlPaths.Messages)}
            >
              <IconWrapper>
                <BsShieldFillCheck />
                <span>Messages</span>
              </IconWrapper>
              <IoChevronForward />
            </StyledNavLink>
          </StyledNavItem>
          <StyledNavItem>
            <StyledNavLink
              href={UrlPaths.Payments}
              active={setActive(UrlPaths.Payments)}
            >
              <IconWrapper>
                <IoWallet />
                <span>Payments</span>
              </IconWrapper>
              <IoChevronForward />
            </StyledNavLink>
          </StyledNavItem>
          <StyledNavItem>
            <StyledNavLink
              href={UrlPaths.Templates}
              active={setActive(UrlPaths.Templates)}
            >
              <IconWrapper>
                <BsFillBookmarkDashFill />
                <span>Templates</span>
              </IconWrapper>
              <IoChevronForward />
            </StyledNavLink>
          </StyledNavItem>
        </StyledNav>
        {/* </Navbar> */}
      </div>
    </StyledNavBar>
  );
}
