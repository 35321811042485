import { useCallback, useState} from "react";
import { UploadDestinations } from "../utilities/types";
import { headers } from "./authHeader";

export default function useDeleteFileData() {
  const [errorFileDelete, setErrorFileDelete] = useState<any>(null);

  function getDestination(key: keyof typeof UploadDestinations): string {
    return UploadDestinations[key];
  }

  const deleteDataFiles = useCallback(
    async (clientFileId: number, destination:keyof typeof UploadDestinations) => {

      const uploadDestination = getDestination(destination);
      let URL = `https://hometrustapi.azurewebsites.net${uploadDestination}/`
      console.log("DELETE FILE PATH", URL)

      setErrorFileDelete(null);

      try {
        const response = await fetch(URL + `${clientFileId}`, { headers, method: "DELETE" });

        if (!response.ok) {
          throw new Error(
            "Server returned error code: " +
            response.status +
            " " +
            (await response.text())
          );
        }
      } catch (error: any) {
        setErrorFileDelete(error);
      }
    },
    []
  );

  return { errorFileDelete, deleteDataFiles };
}
