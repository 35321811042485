import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
body {
  font-family: 'Inter', sans-serif;
  background-color: #B7B2B240;
}

.page-layout {
  height: 100vh;
  display: flex;
  flex-direction: row;
}

.content {
  flex: 1;
  display: flex;

}

.main-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.viewport {
  overflow-y: scroll;
  height: 100%;
}
`;

export default GlobalStyle;
