import { useState } from "react";
import { useLocation } from "wouter";
import { UrlPaths } from "../../utilities/types";
import { headers } from "../authHeader";

export default function usePostNewClient() {
  const [, setLocation] = useLocation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  // TODO get client type
  const submitClient = async (clientData: any) => {
    const url = "https://hometrustapi.azurewebsites.net/api/Clients";

    clientData["dateCreated"] = new Date();
    clientData["createdBy"] = localStorage.getItem("role"); // TODO change this to name later

    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(url, {
        headers,
        method: "POST",
        body: JSON.stringify(clientData),
      });

      if (!response.ok) {
        throw new Error("Server returned error code: " + response.status);
      }
    } catch (error: any) {
      setError(error);
      setIsLoading(false);
    }
    setIsLoading(false);
    setLocation(UrlPaths.Clients);
  };

  return { submitClient, isLoading, error };
}
