import { Col, Form, Row } from "react-bootstrap";
import { TRealEstateData, TTrustsData } from "../../../utilities/trustTypes";
import { getDefaultRealEstateData } from "../../../utilities/defaultDataTypes";
import styled from "styled-components";
import MaskedInput from "react-text-mask";

const StyledSecondaryH5 = styled.h5`
  color: #656e51;
  font-weight: bold;
`;

export const defaultRealEstateData: TRealEstateData =
  getDefaultRealEstateData();

export default function SigningDetails({
  formData,
  setFormData,
  readOnly,
}: {
  formData: TTrustsData;
  setFormData: (trustsData: TTrustsData) => void;
  readOnly?: boolean;
}) {
  const updateCurrentSigningDetails = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    key: keyof TRealEstateData
  ) => {
    if (readOnly) return;
    let value: string | boolean = event.currentTarget.value;

    if (value === "true") {
      value = true;
    } else if (value === "false") {
      value = false;
    }
    const updatedSigningDetails = {
      ...formData.realEstate,
      [key]: value,
    };

    setFormData({
      ...formData,
      realEstate: updatedSigningDetails,
    });
  };
  return (
    <>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="signingDate">
            <Form.Label>Date of Signing Docs</Form.Label>
            <Form.Control
              name="signingDate"
              type="date"
              value={formData.realEstate?.signingDate}
              onChange={(target) =>
                updateCurrentSigningDetails(target, "signingDate")
              }
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="signingCity">
            <Form.Label>City of Signing Docs</Form.Label>
            <Form.Control
              name="signingCity"
              type="text"
              value={formData.realEstate?.signingCity}
              onChange={(target) =>
                updateCurrentSigningDetails(target, "signingCity")
              }
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="signingCounty">
            <Form.Label>County of Signing Docs</Form.Label>
            <Form.Control
              name="signingCounty"
              type="text"
              value={formData.realEstate?.signingCounty}
              onChange={(target) =>
                updateCurrentSigningDetails(target, "signingCounty")
              }
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <StyledSecondaryH5>Witnesses</StyledSecondaryH5>
      </Row>
      <Row>
        <Col>
          <Form.Group controlId="witnesses">
            <Form.Label>Witnesses</Form.Label>
            <Form.Group className="mb-3">
              <Form.Check
                inline
                name="witness"
                type="checkbox"
                label="Carol Mariani"
                value="Carol Mariani"
                // checked={(formData.trust?.perStirpesOrCapita || "").includes(
                //   "Per Stirpes"
                // )}
                // onChange={(event) =>
                //   updateCurrentTrust(event, "perStirpesOrCapita")
                // }
              ></Form.Check>
              <Form.Check
                inline
                name="witness"
                type="checkbox"
                label="Marco Mariani"
                value="Marco Mariani"
                // checked={(formData.trust?.perStirpesOrCapita || "").includes(
                //   "Per Stirpes"
                // )}
                // onChange={(event) =>
                //   updateCurrentTrust(event, "perStirpesOrCapita")
                // }
              ></Form.Check>
              <Form.Check
                inline
                name="witness"
                type="checkbox"
                label="Michaela Mariani"
                value="Michaela Mariani"
                // checked={(formData.trust?.perStirpesOrCapita || "").includes(
                //   "Per Stirpes"
                // )}
                // onChange={(event) =>
                //   updateCurrentTrust(event, "perStirpesOrCapita")
                // }
              ></Form.Check>
              <Form.Check
                inline
                name="witness"
                type="checkbox"
                label="Other"
                value="Other"
                // checked={(formData.trust?.perStirpesOrCapita || "").includes(
                //   "Per Stirpes"
                // )}
                // onChange={(event) =>
                //   updateCurrentTrust(event, "perStirpesOrCapita")
                // }
              ></Form.Check>
            </Form.Group>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="otherWitness">
            <Form.Label>Name of Other Witness</Form.Label>
            <Form.Control
              name="otherWitness"
              type="text"
              //   value={formData.realEstate?.signingCity}
              //   onChange={(target) =>
              //     updateCurrentSigningDetails(target, "signingCity")
              //   }
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <StyledSecondaryH5>Notary</StyledSecondaryH5>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="notaryName">
            <Form.Label>Notary Name</Form.Label>
            <Form.Control
              name="notaryName"
              type="text"
              value={formData.realEstate?.notaryName}
              onChange={(target) =>
                updateCurrentSigningDetails(target, "notaryName")
              }
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="notaryPhone">
            <Form.Label>Notary Phone</Form.Label>
            <MaskedInput
              name="notaryPhone"
              className="form-control"
              mask={[
                "(",
                /[1-9]/,
                /\d/,
                /\d/,
                ")",
                " ",
                /\d/,
                /\d/,
                /\d/,
                "-",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
              ]}
              value={formData.realEstate?.notaryPhone}
              onChange={(target) =>
                updateCurrentSigningDetails(target, "notaryPhone")
              }
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="otherNotaryName">
            <Form.Label>Other Notary Name</Form.Label>
            <Form.Control
              name="otherNotaryName"
              type="text"
              // value={formData.realEstate?.notaryName}
              // onChange={(target) =>
              //   updateCurrentSigningDetails(target, "notaryName")
              // }
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="otherNotaryPhone">
            <Form.Label>Other Notary Phone</Form.Label>
            <MaskedInput
              className="form-control"
              name="otherNotaryPhone"
              mask={[
                "(",
                /[1-9]/,
                /\d/,
                /\d/,
                ")",
                " ",
                /\d/,
                /\d/,
                /\d/,
                "-",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
              ]}
              // value={formData.realEstate?.notaryPhone}
              // onChange={(target) =>
              //   updateCurrentSigningDetails(target, "notaryPhone")
              // }
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <StyledSecondaryH5>Realtor</StyledSecondaryH5>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="realtorName">
            <Form.Label>Realtor Name</Form.Label>
            <Form.Control
              name="realtorName"
              type="text"
              value={formData.realEstate?.realtorName}
              onChange={(target) =>
                updateCurrentSigningDetails(target, "realtorName")
              }
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="realtorCompany">
            <Form.Label>Realtor Company</Form.Label>
            <Form.Control
              name="realtorCompany"
              type="text"
              value={formData.realEstate?.realtorCompany}
              onChange={(target) =>
                updateCurrentSigningDetails(target, "realtorCompany")
              }
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="realtorPhone">
            <Form.Label>Realtor Phone</Form.Label>
            <MaskedInput
              name="Realtor Phone"
              className="form-control"
              mask={[
                "(",
                /[1-9]/,
                /\d/,
                /\d/,
                ")",
                " ",
                /\d/,
                /\d/,
                /\d/,
                "-",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
              ]}
              value={formData.realEstate?.realtorPhone}
              onChange={(target) =>
                updateCurrentSigningDetails(target, "realtorPhone")
              }
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  );
}
