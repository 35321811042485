import { Table, Button, ButtonToolbar, Spinner } from "react-bootstrap";
import useGetTrustData, {
  TTrustData,
} from "../../hooks/trustHooks/useGetTrustData";
import { useEffect, useState } from "react";
import styled from "styled-components";
import Modal from "react-bootstrap/Modal";
import { FaCircleInfo } from "react-icons/fa6";

import DataListPagination from "../tools/ListPagination";
import DataListSearch from "../tools/ListSearch";
import useGetAveryTemplatesData from "../../hooks/templateHooks/useGetAveryLabelsTemplate";
import useGetBinderBasicsTemplatesData from "../../hooks/templateHooks/useGetBinderBasicsTemplate";
import useGetVialOfLifeTemplateData from "../../hooks/templateHooks/useGetVialOfLifeTemplate";
import useGetSPTTemplateData from "../../hooks/templateHooks/useGetSPTTemplate";
import useGetCPTTemplateData from "../../hooks/templateHooks/useGetCPTTemplate";
import useGetTATemplateData from "../../hooks/templateHooks/useGetTATemplate";
import useGetTrustCertificateTemplate from "../../hooks/templateHooks/useGetTrustCertificateTemplate";
import useGetTrustTransferDeedTemplate from "../../hooks/templateHooks/useGetTrustTransferDeedTemplate";
import useGetLoveLetterTemplateData from "../../hooks/templateHooks/useGetLoveLetterTemplate";
import { IoArrowDown, IoArrowUp } from "react-icons/io5";

const TrustTable = styled(Table).attrs(() => ({
  className: "w-100",
}))`
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 8px;
  overflow: hidden;

  th,
  td {
    padding: 10px 14px;
    border-bottom: 1px solid #e0e0e0;
  }

  tr:last-child {
    th,
    td {
      border-bottom: none;
    }
  }

  thead th {
    background-color: #f5f5f5;
    font-weight: bold;
    text-align: left;
  }

  thead th {
    text-align: center;
  }

  tbody tr {
    cursor: pointer;
  }

  th {
    text-align: center;
  }

  th:nth-child(1) {
    width: 20%;
    text-align: left;
  }
  th:nth-child(2) {
    width: 15%;
  }
  th:nth-child(3) {
    width: 10%;
  }
  th:nth-child(4) {
    width: 15%;
  }
  th:nth-child(5) {
    width: 5%;
  }

  .button-container {
    display: flex;
    justify-content: center;
  }

  .action-button {
    padding: 6px 12px;
    font-size: 0.9em;
  }
`;

const SearchPrompt = styled.div`
  display: flex;
  align-items: center;
  margin: 50px 0;
  color: grey;
  font-size: 20px;
`;

export default function GenerateTemplateModal({
  handleClose,
  docName,
}: {
  handleClose: () => void;
  docName: string;
}) {
  const { trustData, totalPages, isLoading, fetchData } = useGetTrustData();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [orderBy, setOrderBy] = useState<string | null>(null);
  const [ascending, setAscending] = useState<boolean | null>(null);
  const [inProgressSearchTerm, setInProgressSearchTerm] = useState<string>("");
  const { fetchAveryLabels, isLoadingAveryLabels } = useGetAveryTemplatesData();
  const { fetchBinderBasics, isLoadingBinderBasicsTemplate } =
    useGetBinderBasicsTemplatesData();
  const { fetchVialOfLife, isLoadingVialOfLifeTemplate } =
    useGetVialOfLifeTemplateData();
  const { fetchCPTTemplate, isLoadingCPTTemplate } = useGetCPTTemplateData();
  const { fetchSPTTemplate, isLoadingSPTTemplate } = useGetSPTTemplateData();
  const { fetchTATemplate, isLoadingTATemplate } = useGetTATemplateData();
  const { fetchTrustCertificate, isLoadingTrustCertificateTemplate } =
    useGetTrustCertificateTemplate();
  const { fetchTrustTransferDeed, isLoadingTrustTransferDeedTemplate } =
    useGetTrustTransferDeedTemplate();
  const { fetchLoveLetter, isLoadingLoveLetterTemplate } =
    useGetLoveLetterTemplateData();

  useEffect(() => {
    let trustType: string | null = null;

    switch (docName) {
      case "SPT":
      case "CPT":
      case "TA":
        trustType = docName;
        break;
    }

    if (!!searchTerm && searchTerm.length > 0) {
      fetchData(pageNumber, searchTerm, trustType, orderBy, ascending);
    }
  }, [fetchData, pageNumber, searchTerm, docName, orderBy, ascending]);

  type Column = {
    label: string;
    field: string;
  };

  const columns: Column[] = [
    { label: "Trust Name", field: "name" },
    { label: "Trust Type", field: "type" },
    { label: "Surname", field: "familyName" },
    { label: "Trust Date", field: "date" },
  ];

  const handleSort = (orderByType: string) => {
    if (orderBy === orderByType && !ascending) {
      setOrderBy(null);
      setAscending(true);
    } else if (orderBy === orderByType) {
      setAscending(!ascending);
    } else {
      setOrderBy(orderByType);
      setAscending(true);
    }
  };

  const capitalizeWords = (input_str: string | null) => {
    if (input_str) {
      return input_str
        .toLocaleLowerCase()
        .replace(/\b\w/g, (char: string) => char.toUpperCase());
    }
    return "N/A";
  };

  let trustTableData = trustData ?? [];

  const submit = (trustId: string, trustName: string) => {
    switch (docName) {
      case "Avery Labels":
        fetchAveryLabels(trustId, trustName);
        break;
      case "Binder Basics":
        fetchBinderBasics(trustId, trustName);
        break;
      case "Vial of Life":
        fetchVialOfLife(trustId, trustName);
        break;
      case "CPT":
        fetchCPTTemplate(trustId, trustName);
        break;
      case "SPT":
        fetchSPTTemplate(trustId, trustName);
        break;
      case "TA":
        fetchTATemplate(trustId, trustName);
        break;
      case "Trust Certificate":
        fetchTrustCertificate(trustId, trustName);
        break;
      case "Trust Transfer Deed":
        fetchTrustTransferDeed(trustId, trustName);
        break;
      case "Love Letter":
        fetchLoveLetter(trustId, trustName);
        break;
    }

    if (
      !isLoadingVialOfLifeTemplate ||
      !isLoadingAveryLabels ||
      !isLoadingBinderBasicsTemplate ||
      isLoadingCPTTemplate ||
      isLoadingSPTTemplate ||
      isLoadingTATemplate ||
      isLoadingTrustCertificateTemplate ||
      isLoadingLoveLetterTemplate
    ) {
      handleClose();
    }
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Generate {docName} Template</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ButtonToolbar className="justify-content-between w-100 p-0">
          <DataListSearch
            setSearchTerm={setSearchTerm}
            setPageNumber={setPageNumber}
            inProgressSearchTerm={inProgressSearchTerm}
            setInProgressSearchTerm={setInProgressSearchTerm}
            page={"Clients"}
          />
          <DataListPagination
            totalPages={totalPages}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        </ButtonToolbar>
        {trustTableData.length > 0 ? (
          <TrustTable>
            <thead>
              <tr>
                {columns.map(({ label, field }) => (
                  <th
                    key={field}
                    onClick={() => handleSort(field)}
                    style={{ cursor: "pointer" }}
                  >
                    {label}{" "}
                    {orderBy === field && (
                      <span style={{ marginLeft: "8px" }}>
                        {ascending ? <IoArrowUp /> : <IoArrowDown />}
                      </span>
                    )}
                  </th>
                ))}
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ||
              isLoadingVialOfLifeTemplate ||
              isLoadingAveryLabels ||
              isLoadingVialOfLifeTemplate ||
              isLoadingCPTTemplate ||
              isLoadingSPTTemplate ||
              isLoadingTATemplate ||
              isLoadingTrustCertificateTemplate ||
              isLoadingTrustTransferDeedTemplate ||
              isLoadingLoveLetterTemplate ? (
                <tr>
                  <td colSpan={7}>
                    <div className="d-flex justify-content-center align-items-center">
                      <Spinner
                        animation="border"
                        role="status"
                        variant="primary"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </div>
                  </td>
                </tr>
              ) : (
                trustTableData.map((data: TTrustData) => (
                  <tr key={data.id}>
                    <th>{capitalizeWords(data.name)}</th>
                    <th>{data.type}</th>
                    <th>{capitalizeWords(data.familyName)}</th>
                    <th>{data.date}</th>
                    <th>
                      <Button
                        onClick={() => {
                          submit(data.id, data.name);
                        }}
                      >
                        Select
                      </Button>
                    </th>
                  </tr>
                ))
              )}
            </tbody>
          </TrustTable>
        ) : isLoading ||
          isLoadingVialOfLifeTemplate ||
          isLoadingAveryLabels ||
          isLoadingVialOfLifeTemplate ||
          isLoadingCPTTemplate ||
          isLoadingSPTTemplate ||
          isLoadingTATemplate ||
          isLoadingTrustCertificateTemplate ||
          isLoadingTrustTransferDeedTemplate ||
          isLoadingLoveLetterTemplate ? (
          <div className="d-flex justify-content-center align-items-center">
            <Spinner animation="border" role="status" variant="primary">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <SearchPrompt className="h-100 d-flex justify-content-center">
            <FaCircleInfo />
            <span style={{ marginLeft: "8px" }}>
              Type a Trust name in the search bar above to display a list of
              trusts
            </span>
          </SearchPrompt>
        )}
      </Modal.Body>
    </>
  );
}
