import { useEffect, useState } from "react";
import { useParams } from "wouter";
import { Table, Container, ButtonToolbar } from "react-bootstrap";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import FileUpload from "../tools/FileUpload";
import usePostNewFile from "../../hooks/clientHooks/usePostNewFile";
import useGetFileData, { TFileData } from "../../hooks/clientHooks/useGetFileData";
import useDeleteFileData from "../../hooks/useDeleteFileData";
import DataListPagination from "../tools/ListPagination";
import DataListSearch from "../tools/ListSearch";

const ClientFileTable = styled(Table).attrs({
  className: "w-100",
})`
  tr {
    cursor: pointer;
  }

  .button-container {
    display: flex;
    gap: 8px;
  }
`;

export default function ClientFileTab() {
  const params = useParams();
  const { errorFileDelete, deleteDataFiles } = useDeleteFileData();
  const { submitFile, isLoadingPost, errorPost } = usePostNewFile();
  const {
    clientFileData,
    isLoading,
    error,
    fetchDataFiles,
    totalPages,
    downloadClientFile,
    setClientFileData,
  } = useGetFileData();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [inProgressSearchTerm, setInProgressSearchTerm] = useState<string>("");

  const clientID = params.id ?? "";

  useEffect(() => {
    fetchDataFiles(pageNumber, searchTerm, Number(clientID));
  }, [fetchDataFiles, pageNumber, searchTerm, clientID]);

  const clientFileDataList = clientFileData ?? [];

  const sendNewFileData = async (formData: FormData, destination: string) => {
    await submitFile(formData, destination);
    if (errorPost) {
      console.error("error uploading file");
    } else {
      fetchDataFiles(pageNumber, searchTerm, Number(clientID));
    }
  };

  const downloadFile = (clientFileData: TFileData): any => {
    downloadClientFile(clientFileData.id, clientFileData.name);
  };

  const deleteFile = async (clientFileData: TFileData) => {
    await deleteDataFiles(clientFileData.id, "CLIENT_FILES");
    if (errorFileDelete) {
      console.error(errorFileDelete);
    } else {
      setClientFileData((prevData: any) =>
        prevData?.filter((file: TFileData) => file.id !== clientFileData.id)
      );
    }
  };

  return (
    <>
      <Container fluid>
        <ButtonToolbar className="justify-content-between w-100 p-0">
          <DataListSearch
            setSearchTerm={setSearchTerm}
            setPageNumber={setPageNumber}
            inProgressSearchTerm={inProgressSearchTerm}
            setInProgressSearchTerm={setInProgressSearchTerm}
            page={"Client Files"}
          />
          <DataListPagination
            totalPages={totalPages}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        </ButtonToolbar>
        <ClientFileTable bordered>
          <thead>
            <tr>
              <th>File Name</th>
              <th>File Type</th>
              <th>Date Created</th>
              <th>Date Modified</th>
              <th>Catagory</th>
              <th>File size</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {clientFileDataList.length > 0 ? (
              clientFileDataList.map((data: TFileData) => (
                <tr key={data.id}>
                  <th>{data.name}</th>
                  <th>{data.docType}</th>
                  <th>{data.dateCreated}</th>
                  <th>{data.dateModified}</th>
                  <th>{data.category}</th>
                  <th>{data.size}</th>
                  <th>
                    <div className="button-container">
                      <Button style={{ padding: "8px 16px" }}>Edit</Button>
                      <Button
                        style={{ padding: "8px 16px" }}
                        onClick={() => downloadFile(data)}
                      >
                        Download
                      </Button>
                      <Button
                        style={{ padding: "8px 16px" }}
                        onClick={() => deleteFile(data)}
                      >
                        Delete
                      </Button>

                    </div>
                  </th>
                </tr>
              ))
            ) : isLoading ? (
              <tr>
                <th>Loading... </th>
              </tr>
            ) : (
              <tr>
                <th>No data found</th>
              </tr>
            )}
          </tbody>
        </ClientFileTable>
        <FileUpload
          fileData={null}
          isLoading={isLoadingPost}
          error={errorPost}
          apiFunction={sendNewFileData}
          clientId={Number(clientID)}
          destination="CLIENT_FILES"
        />
      </Container>
      {error != null ? <div>The error is: {error.message}</div> : null}
    </>
  );
}
