import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { CustomerUrlPaths } from "../../utilities/types";
import { useLocation } from "wouter";

export default function CustomerLeftNavbar() {
  const [currentPath] = useLocation();

  const path = currentPath as CustomerUrlPaths;

  // TODO: add support for Array<UrlPaths> so I can add subpages
  const setActive = (pathToMatch: CustomerUrlPaths): boolean =>
    pathToMatch === path;
  // TODO: overwrite active Nav.Item color styling
  return (
    <div className="left-navbar">
      <Navbar bg="link" className="flex-sm-column">
        <Nav className="flex-sm-column">
          <div>Home</div>
          <Nav.Item>
            <Nav.Link
              href={CustomerUrlPaths.CustomerDashboard}
              active={setActive(CustomerUrlPaths.CustomerDashboard)}
            >
              Dashboard
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Nav className="flex-sm-column">
          <div>Pages</div>
          <Nav.Item>
            <Nav.Link
              href={CustomerUrlPaths.Documents}
              active={setActive(CustomerUrlPaths.Documents)}
            >
              My Documents
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              href={CustomerUrlPaths.Profile}
              active={setActive(CustomerUrlPaths.Profile)}
            >
              Profile
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              href={CustomerUrlPaths.RequestService}
              active={setActive(CustomerUrlPaths.RequestService)}
            >
              Request a Service
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Navbar>
    </div>
  );
}
