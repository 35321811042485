import Button from "react-bootstrap/Button";
import { UrlPaths } from "../../utilities/types";
import Stack from "react-bootstrap/Stack";
// import RecentCasesTable from "./RecentCasesTable";
import styled from "styled-components";

const StyledDashboardContainer = styled.div`
  margin-top: 40px;
`;

export default function Dashboard() {
  return (
    <>
      <StyledDashboardContainer>
        <Stack gap={4} className="mb-5">
          <Stack
            direction="horizontal"
            gap={5}
            className="justify-content-center"
          >
            <Button
              href={UrlPaths.Trusts}
              variant="light"
              size="lg"
              className="w-25"
            >
              Trusts
            </Button>
            <Button
              href={UrlPaths.Payments}
              variant="light"
              size="lg"
              className="w-25"
            >
              Payments
            </Button>
          </Stack>
          <Stack
            direction="horizontal"
            gap={5}
            className="justify-content-center"
          >
            <Button variant="light" size="lg" className="w-25">
              Binder Basics
            </Button>
            <Button variant="light" size="lg" className="w-25">
              New Trust
            </Button>
          </Stack>
          <Stack
            direction="horizontal"
            gap={5}
            className="justify-content-center"
          >
            <Button variant="light" size="lg" className="w-25">
              Restatement
            </Button>
            <Button variant="light" size="lg" className="w-25">
              Trust Administration
            </Button>
          </Stack>
          <Stack
            direction="horizontal"
            gap={5}
            className="justify-content-center"
          >
            <Button variant="light" size="lg" className="w-25">
              New Deed
            </Button>
            <Button variant="light" size="lg" className="w-25">
              Vial of Life
            </Button>
          </Stack>
        </Stack>
        {/* <RecentCasesTable /> */}
      </StyledDashboardContainer>
    </>
  );
}
