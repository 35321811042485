import { useCallback, useState } from "react";
import { CustomerUrlPaths } from "../utilities/types";
import { useLocation } from "wouter";

export default function useUserRegister() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [, setLocation] = useLocation();

  const sendRegister = useCallback(
    async (registerData: {
      firstName: string;
      lastName: string;
      userName: string;
      phone: string;
      email: string;
      password: string;
    }) => {
      const headers = { "Content-type": "application/json" };
      const url =
        "https://hometrustapi.azurewebsites.net/api/Authenticate/register";

      setIsLoading(true);
      setError(null);

      const {
        firstName,
        lastName,
        userName: username,
        phone,
        email,
        password,
      } = registerData;

      try {
        const response = await fetch(url, {
          headers,
          body: JSON.stringify({
            username,
            email,
            phone,
            password,
            firstName,
            lastName,
          }),
          method: "POST",
        });

        if (!response.ok) {
          throw new Error(
            "Server returned error code: " +
              response.status +
              " " +
              (await response.text())
          );
        }

        // const jsonResponse = await response.json();
        // console.log(jsonResponse);
        // JSON response looks like this. {status: 'Success', message: 'User created successfully!'}
        // TODO maybe have some confirmation later down the road.

        setLocation(CustomerUrlPaths.CustomerDashboard);
      } catch (error: any) {
        setError(error);
        setIsLoading(false);
      }
      setIsLoading(false);
    },
    [setLocation]
  );

  return { sendRegister, isLoading, error };
}
