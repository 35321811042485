import styled from "styled-components";
import { Col, Container, Row } from "react-bootstrap";

//TO DO: make container responsive
const FooterContainer = styled(Container)`
  font-size: 12px;
  line-height: 10px;
  padding: 16px 24px;
  background-color: #ffffff;
`;

const StyledCol = styled(Col)`
  text-align: right;

  span {
    font-size: 14px;
  }
`;

export default function Footer() {
  return (
    <FooterContainer>
      <Row>
        <Col md={2}>Privacy Policy</Col>
        <Col md={2}>Terms of Use</Col>
        <StyledCol xs={8}>
          <span>©</span> 2024 HomeTrust, LLC All Rights Reserved
        </StyledCol>
      </Row>
    </FooterContainer>
  );
}
