import { MdOutlineConstruction } from "react-icons/md";

export default function Viewport() {
  return (
    <div>
      <h4 style={{display: "flex", justifyContent: "center", alignContent: "center"}}>Coming Soon</h4>
      <div style={{padding: "20px 35%", textAlign: "center"}}>Thanks for your patience! This feature is still in development, and we’re working hard to bring it to you. Stay tuned for updates and check back soon for more details. In the meantime, feel free to explore the rest of our site!</div>
      <div style={{display: "flex", justifyContent: "center"}}>
        <MdOutlineConstruction size="25em"color="lightgray"/>
      </div>
    </div>
  );
}
