import { Col, Form, Row } from "react-bootstrap";
import { TTrustData, TTrustsData } from "../../../utilities/trustTypes";
import { getDefaultTrustData } from "../../../utilities/defaultDataTypes";
import styled from "styled-components";

const StyledFormText = styled(Form.Text)`
  margin-top: 0;
  margin-bottom: 0.5rem;
`;

export const defaultTrustData: TTrustData = getDefaultTrustData();

export default function Directives({
  formData,
  setFormData,
  readOnly,
}: {
  formData: TTrustsData;
  setFormData: (trustsData: TTrustsData) => void;
  readOnly?: boolean;
}) {
  const updateCurrentDirective = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    key: keyof TTrustData
  ) => {
    if (readOnly) return;
    let value: string | boolean = event.currentTarget.value;

    if (value === "true") {
      value = true;
    } else if (value === "false") {
      value = false;
    }

    if (!formData.trust) return;

    const updatedTrust = {
      ...formData.trust,
      [key]: value,
    };

    setFormData({
      ...formData,
      trust: updatedTrust,
    });
  };
  return (
    <>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="newTrustDirective1">
            <Form.Label>Directive 1</Form.Label>
            <StyledFormText className="d-block">
              Property Paragraph
            </StyledFormText>
            <Form.Control
              name="directive1"
              as="textarea"
              value={formData.trust?.directive1}
              onChange={(target) =>
                updateCurrentDirective(target, "directive1")
              }
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="newTrustDirective2">
            <Form.Label>Directive 2</Form.Label>
            <StyledFormText className="d-block">
              Financial Institutions/Finances Paragraph
            </StyledFormText>
            <Form.Control
              name="directive2"
              as="textarea"
              value={formData.trust?.directive2}
              onChange={(target) =>
                updateCurrentDirective(target, "directive2")
              }
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="newTrustDirective3">
            <Form.Label>Directive 3</Form.Label>
            <StyledFormText className="d-block mt-0">
              Specific Gifts
            </StyledFormText>
            <Form.Control
              name="directive3"
              as="textarea"
              value={formData.trust?.directive3}
              onChange={(target) =>
                updateCurrentDirective(target, "directive3")
              }
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="newTrustDirective4">
            <Form.Label>Directive 4</Form.Label>
            <Form.Control
              name="directive4"
              as="textarea"
              value={formData.trust?.directive4}
              onChange={(target) =>
                updateCurrentDirective(target, "directive4")
              }
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="newTrustDirective5">
            <Form.Label>Directive 5</Form.Label>
            <Form.Control
              name="directive5"
              as="textarea"
              value={formData.trust?.directive5}
              onChange={(target) =>
                updateCurrentDirective(target, "directive5")
              }
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  );
}
