import Button from "react-bootstrap/Button";
import { UrlPaths } from "../../utilities/types";

export default function FourOFour() {
  return (
    <>
      <div>Not found</div>
      <Button href={UrlPaths.Home}>Go Back to homepage</Button>
    </>
  );
}
