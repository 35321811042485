import { Container, Tab, Tabs, Row, Col } from "react-bootstrap";
import { useParams } from "wouter";
import { useEffect } from "react";
import { HTTPMethods } from "../../utilities/types";
import styled from "styled-components";
import useOneClientData from "../../hooks/clientHooks/useOneClientData";
import ClientInfoTab from "./ClientInfoTab";
import ClientFileTab from "./ClientFileTab";

// const ClientView = styled(Container).attrs(() => ({
//   className: "w-100",
// }))`
//   background-color: #ffffff;
//   border-collapse: separate;
//   padding: 5px;
//   border-radius: 8px;
//   overflow: hidden;
// `;

const ClientView = styled(Container)`
  background-color: #ffffff;
  border-collapse: separate;
  padding: 5px;
  border-radius: 8px;
  overflow: hidden;
`;

export default function ViewClient() {
  const params = useParams();
  const { oneClientData, clientData, error } = useOneClientData();

  const clientID = params.id ?? "";

  useEffect(() => {
    oneClientData(clientID, HTTPMethods.GET);
  }, [oneClientData, clientID]);

  return (
    <Container fluid>
      <Row className="m-3 justify-content-center border">
        <Row className="justify-content-center">
          <Col className="col-auto">
            <h3>Client Details</h3>
          </Col>
        </Row>
      </Row>
      <ClientView fluid>
        <Tabs
          id="controlled-tab-example"
          defaultActiveKey="clientInfo"
          className="mb-3"
        >
          <Tab eventKey="clientInfo" title="Client Information">
            <ClientInfoTab
              clientID={clientID}
              clientData={clientData}
              error={error}
            />
          </Tab>
          <Tab eventKey="files" title="Files">
            <ClientFileTab />
          </Tab>
        </Tabs>
      </ClientView>
    </Container>
  );
}
