import { useCallback, useState } from "react";
import { headers } from "../authHeader";

export default function useGetTrustCertificateData() {
  const [
    isLoadingTrustCertificateTemplate,
    setIsLoadingTrustCertificateTemplate,
  ] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  const fetchTrustCertificate = useCallback(
    async (trustId: string, trustName: string) => {
      let url = `https://hometrustapi.azurewebsites.net/api/Templates/generate/TrustCertificate/${trustId}`;

      setIsLoadingTrustCertificateTemplate(true);
      setError(null);

      console.log("getting Templates");
      try {
        await fetch(url, { headers, method: "GET" })
          .then((response) => response.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `${trustName}_TrustCertificate`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
          })
          .catch((error) => console.error("Error downloading file:", error));
      } catch (error: any) {
        setError(error);
        setIsLoadingTrustCertificateTemplate(false);
      }
    },
    []
  );

  return { isLoadingTrustCertificateTemplate, error, fetchTrustCertificate };
}
