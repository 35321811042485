import { useState } from "react";
import { TemplateUploadEndpoints } from "../../utilities/types";

export default function usePostUploadTemplateData() {
  const [isLoadingUploadTemplate, setIsLoadingUploadTemplate] =
    useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  const uploadTemplate = async (
    templateType: string,
    file: File,
    selectedTrustType?: string
  ) => {
    let urlEndpoint = "";
    console.log("POST", templateType);
    console.log(selectedTrustType);

    switch (templateType) {
      case "SPT":
        urlEndpoint = TemplateUploadEndpoints.SPT;
        break;
      case "Avery Labels":
        if (selectedTrustType === "Avery Labels SPT") {
          urlEndpoint = TemplateUploadEndpoints.AveryLabelsSPT;
        } else {
          urlEndpoint = TemplateUploadEndpoints.AveryLabelsCPT;
        }
        break;
      case "CPT":
        urlEndpoint = TemplateUploadEndpoints.CPT;
        break;
      case "TA":
        urlEndpoint = TemplateUploadEndpoints.TA;
        break;
      case "Vial of Life":
        urlEndpoint = TemplateUploadEndpoints.VialofLife;
        break;
      case "Binder Basics":
        urlEndpoint = TemplateUploadEndpoints.BinderBasics;
        break;
      case "Trust Transfer Deed":
        urlEndpoint = TemplateUploadEndpoints.TrustTransferDeed;
        break;
      case "Trust Certificate":
        urlEndpoint = TemplateUploadEndpoints.TrustCertificate;
        break;
      case "Love Letter":
        urlEndpoint = TemplateUploadEndpoints.LoveLetter;
        break;
      default:
        console.error(
          `No API endpoint available for template type ${templateType}`
        );
        return;
    }

    let url = `https://hometrustapi.azurewebsites.net${urlEndpoint}`;
    const formData = new FormData();
    formData.append("file", file);

    setIsLoadingUploadTemplate(true);
    setError(null);

    try {
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Server returned error code: " + response.status);
      }
      const result = await response.json();
      console.log("Upload successful:", result);
    } catch (error: any) {
      setError(error);
    }
    setIsLoadingUploadTemplate(false);
  };

  return { isLoadingUploadTemplate, error, uploadTemplate };
}
