import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { TClientData } from "../../utilities/clientTypes";
import { useEffect, useState } from "react";
// import BusinessEntity, {
//   defaultBusinessEntityData,
// } from "./form_sub_components/BusinessEntity";
// import BankAccount, {
//   defaultBankAccountData,
// } from "./form_sub_components/BankAccount";
// import LifeInsurancePlan, {
//   defaultLifeInsuranceData,
// } from "./form_sub_components/LifeInsurancePlan";
// import OtherAsset, {
//   defaulOtherAssetsData,
// } from "./form_sub_components/OtherAsset";
// import Person, { defaulPersonData } from "./form_sub_components/Person";
// import Property, { defaultPropertyData } from "./form_sub_components/Property";
// import RetirementPlan, {
//   defaultRetirementPlanData,
// } from "./form_sub_components/RetirementPlan";
// import SafeDepositBox, {
//   defaultSafeDepositBoxData,
// } from "./form_sub_components/SafeDepositBox";
// import Security, { defaultSecurityData } from "./form_sub_components/Security";
// import StockOrBond, {
//   defaultStockAndBondData,
// } from "./form_sub_components/StockOrBond";
// import Vehicle, { defaultVehicleData } from "./form_sub_components/Vehicle";
import styled from "styled-components";

const ClientFormStyles = styled.div`
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 8px;
  overflow: hidden;
  background-color: #ffffff;

  th,
  td {
    padding: 12px 16px;
    border-bottom: 1px solid #e0e0e0;
  }

  tr:last-child {
    th,
    td {
      border-bottom: none;
    }
  }

  thead th {
    background-color: #f5f5f5;
    font-weight: bold;
    text-align: left;
  }
`;
const defaultClientData: TClientData = {
  id: 0,
  firstName: "",
  lastName: "",
  otherNamesUsed: "",
  otherMarriedNames: "",
  maidenName: "",
  gender: "",
  email: "",
  phoneNumber: "",
  phoneNumber2: "",
  ssn: "",
  maritalStatus: "",
  dob: "",
  address: "",
  address2: "",
  city: "",
  state: "",
  zip: "",
  // bankAccounts: null,
  // businessEntities: null,
  // lifeInsurances: null,
  // otherAssets: null,
  // people: null,
  // realEstate: null,
  // retirementPlans: null,
  // safeDepositBoxes: null,
  // securities: null,
  // stockAndBonds: null,
  // vehicles: null,
};

export default function ClientForm({
  clientData,
  isLoading,
  error,
  apiFunction,
  readOnly,
}: {
  clientData: TClientData | null;
  isLoading: boolean;
  error: any;
  apiFunction?: (clientData: TClientData) => void;
  readOnly?: boolean;
}) {
  const [formData, setFormData] = useState<TClientData>(defaultClientData);

  useEffect(() => {
    if (clientData != null) {
      setFormData(clientData);
    }
  }, [setFormData, clientData]);

  const onFormSubmit = (e: any) => {
    e.preventDefault();
    apiFunction?.(formData);
  };

  return (
    <ClientFormStyles>
      <Row>
        <h4>Old Client Information</h4>
      </Row>
      <Row>
        <Form onSubmit={onFormSubmit} aria-disabled>
          <fieldset disabled={readOnly ? true : false}>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="newClientFirstName">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    name="firstName"
                    type="text"
                    value={formData.firstName}
                    onChange={({ target: { value } }) =>
                      setFormData({ ...formData, firstName: value })
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="newClientLastName">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    name="lastName"
                    type="text"
                    value={formData.lastName}
                    onChange={({ target: { value } }) =>
                      setFormData({ ...formData, lastName: value })
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="newClientOtherNames">
                  <Form.Label>Other Names Used</Form.Label>
                  <Form.Control
                    name="otherNamesUsed"
                    type="text"
                    value={formData.otherNamesUsed}
                    onChange={({ target: { value } }) =>
                      setFormData({ ...formData, otherNamesUsed: value })
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group
                  className="mb-3"
                  controlId="newClientOtherMarriedNames"
                >
                  <Form.Label>Other Married Names</Form.Label>
                  <Form.Control
                    name="otherMarriedNames"
                    type="text"
                    value={formData.otherMarriedNames}
                    onChange={({ target: { value } }) =>
                      setFormData({ ...formData, otherMarriedNames: value })
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="newClientMaidenName">
                  <Form.Label>Maiden Name</Form.Label>
                  <Form.Control
                    name="maidenName"
                    type="text"
                    value={formData.maidenName}
                    onChange={({ target: { value } }) =>
                      setFormData({ ...formData, maidenName: value })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className="col-auto">
                <Form.Group controlId="newClientGender">
                  <Form.Label className="mx-2">Gender</Form.Label>
                  <Form.Group className="mb-3 border">
                    <Form.Check
                      inline
                      name="gender"
                      type="radio"
                      label="Male"
                      value="male"
                      checked={formData.gender === "male"}
                      onChange={({ target: { value } }) =>
                        setFormData({ ...formData, gender: value })
                      }
                    ></Form.Check>
                    <Form.Check
                      inline
                      name="gender"
                      type="radio"
                      label="Female"
                      value="female"
                      checked={formData.gender === "female"}
                      onChange={({ target: { value } }) =>
                        setFormData({ ...formData, gender: value })
                      }
                    ></Form.Check>
                    <Form.Check
                      inline
                      name="gender"
                      type="radio"
                      label="N/A"
                      value="na"
                      checked={formData.gender === "na"}
                      onChange={({ target: { value } }) =>
                        setFormData({ ...formData, gender: value })
                      }
                    ></Form.Check>
                  </Form.Group>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="newClientEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={({ target: { value } }) =>
                      setFormData({ ...formData, email: value })
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="newClientPhone1">
                  <Form.Label>Phone No. 1</Form.Label>
                  <Form.Control
                    name="phoneNumber"
                    type="text"
                    value={formData.phoneNumber}
                    onChange={({ target: { value } }) =>
                      setFormData({ ...formData, phoneNumber: value })
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="newClientPhone2">
                  <Form.Label>Phone No. 2</Form.Label>
                  <Form.Control
                    name="phoneNumber2"
                    type="text"
                    value={formData.phoneNumber2}
                    onChange={({ target: { value } }) =>
                      setFormData({ ...formData, phoneNumber2: value })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="ssn">
                  <Form.Label>SSN</Form.Label>
                  <Form.Control
                    name="ssn"
                    type="text"
                    value={formData.ssn}
                    onChange={({ target: { value } }) =>
                      setFormData({ ...formData, ssn: value })
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="maritalStatus">
                  <Form.Label>Marital Status</Form.Label>
                  <Form.Control
                    name="maritalStatus"
                    type="text"
                    value={formData.maritalStatus}
                    onChange={({ target: { value } }) =>
                      setFormData({ ...formData, maritalStatus: value })
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="dob">
                  <Form.Label>Date of Birth</Form.Label>
                  <Form.Control
                    name="dob"
                    type="text"
                    value={formData.dob}
                    onChange={({ target: { value } }) =>
                      setFormData({ ...formData, dob: value })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="address">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    name="address"
                    type="text"
                    value={formData.address}
                    onChange={({ target: { value } }) =>
                      setFormData({ ...formData, address: value })
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="address2">
                  <Form.Label>Apartment Number</Form.Label>
                  <Form.Control
                    name="address2"
                    type="text"
                    value={formData.address2}
                    onChange={({ target: { value } }) =>
                      setFormData({ ...formData, address2: value })
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="city">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    name="city"
                    type="text"
                    value={formData.city}
                    onChange={({ target: { value } }) =>
                      setFormData({ ...formData, city: value })
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="state">
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    name="state"
                    type="text"
                    value={formData.state}
                    onChange={({ target: { value } }) =>
                      setFormData({ ...formData, state: value })
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="zip">
                  <Form.Label>Zip</Form.Label>
                  <Form.Control
                    name="zip"
                    type="text"
                    value={formData.zip}
                    onChange={({ target: { value } }) =>
                      setFormData({ ...formData, zip: value })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            {/* <hr></hr>
            <Row>
              <h5>People</h5>
            </Row>
            {formData.people?.map((_pe, i) => (
              <Person
                key={i}
                arrayPosition={i}
                formData={formData}
                setFormData={setFormData}
                readOnly={readOnly}
              />
            ))}
            <Row className="justify-content-center">
              {apiFunction != null ? (
                <Button className="w-25" onClick={addPerson}>
                  Add More
                </Button>
              ) : null}
            </Row>
            <hr></hr>
            <Row>
              <h5>Bank Accounts</h5>
            </Row>
            {formData.bankAccounts?.map((_ba, i) => (
              <BankAccount
                key={i}
                arrayPosition={i}
                formData={formData}
                setFormData={setFormData}
                readOnly={readOnly}
              />
            ))}
            <Row className="justify-content-center">
              {apiFunction != null ? (
                <Button className="w-25" onClick={addBankAccount}>
                  Add More
                </Button>
              ) : null}
            </Row>
            <hr></hr>
            <Row>
              <h5>Business Entities</h5>
            </Row>
            {formData.businessEntities?.map((_be, i) => (
              <BusinessEntity
                key={i}
                arrayPosition={i}
                formData={formData}
                setFormData={setFormData}
                readOnly={readOnly}
              />
            ))}
            <Row className="justify-content-center">
              {apiFunction != null ? (
                <Button className="w-25" onClick={addBusinessEntity}>
                  Add More
                </Button>
              ) : null}
            </Row>
            <hr></hr>
            <Row>
              <h5>Life Insurance Plans</h5>
            </Row>
            {formData.lifeInsurances?.map((_li, i) => (
              <LifeInsurancePlan
                key={i}
                arrayPosition={i}
                formData={formData}
                setFormData={setFormData}
                readOnly={readOnly}
              />
            ))}
            <Row className="justify-content-center">
              {apiFunction != null ? (
                <Button className="w-25" onClick={addLifeInsurancePlan}>
                  Add More
                </Button>
              ) : null}
            </Row>
            <hr></hr>
            <Row>
              <h5>Real Estate</h5>
            </Row>
            {formData.realEstate?.map((_re, i) => (
              <Property
                key={i}
                arrayPosition={i}
                formData={formData}
                setFormData={setFormData}
                readOnly={readOnly}
              />
            ))}
            <Row className="justify-content-center">
              {apiFunction != null ? (
                <Button className="w-25" onClick={addProperty}>
                  Add More
                </Button>
              ) : null}
            </Row>
            <hr></hr>
            <Row>
              <h5>Other Assets</h5>
            </Row>
            {formData.otherAssets?.map((_oa, i) => (
              <OtherAsset
                key={i}
                arrayPosition={i}
                formData={formData}
                setFormData={setFormData}
                readOnly={readOnly}
              />
            ))}
            <Row className="justify-content-center">
              {apiFunction != null ? (
                <Button className="w-25" onClick={addOtherAsset}>
                  Add More
                </Button>
              ) : null}
            </Row>
            <hr></hr>
            <Row>
              <h5>Retirement Plans</h5>
            </Row>
            {formData.retirementPlans?.map((_rp, i) => (
              <RetirementPlan
                key={i}
                arrayPosition={i}
                formData={formData}
                setFormData={setFormData}
                readOnly={readOnly}
              />
            ))}
            <Row className="justify-content-center">
              {apiFunction != null ? (
                <Button className="w-25" onClick={addRetirementPlan}>
                  Add More
                </Button>
              ) : null}
            </Row>
            <hr></hr>
            <Row>
              <h5>Safety Deposit Boxes</h5>
            </Row>
            {formData.safeDepositBoxes?.map((_sd, i) => (
              <SafeDepositBox
                key={i}
                arrayPosition={i}
                formData={formData}
                setFormData={setFormData}
                readOnly={readOnly}
              />
            ))}
            <Row className="justify-content-center">
              {apiFunction != null ? (
                <Button className="w-25" onClick={addSafeDepositBox}>
                  Add More
                </Button>
              ) : null}
            </Row>
            <hr></hr>
            <Row>
              <h5>Securities</h5>
            </Row>
            {formData.securities?.map((_se, i) => (
              <Security
                key={i}
                arrayPosition={i}
                formData={formData}
                setFormData={setFormData}
                readOnly={readOnly}
              />
            ))}
            <Row className="justify-content-center">
              {apiFunction != null ? (
                <Button className="w-25" onClick={addSecurity}>
                  Add More
                </Button>
              ) : null}
            </Row>
            <hr></hr>
            <Row>
              <h5>Stock and Bonds</h5>
            </Row>
            {formData.stockAndBonds?.map((_sb, i) => (
              <StockOrBond
                key={i}
                arrayPosition={i}
                formData={formData}
                setFormData={setFormData}
                readOnly={readOnly}
              />
            ))}
            <Row className="justify-content-center">
              {apiFunction != null ? (
                <Button className="w-25" onClick={addStock}>
                  Add More
                </Button>
              ) : null}
            </Row>
            <hr></hr>
            <Row>
              <h5>Vehicles</h5>
            </Row>
            {formData.vehicles?.map((_ve, i) => (
              <Vehicle
                key={i}
                arrayPosition={i}
                formData={formData}
                setFormData={setFormData}
                readOnly={readOnly}
              />
            ))}
            <Row className="justify-content-center">
              {apiFunction != null ? (
                <Button className="w-25" onClick={addVehicle}>
                  Add More
                </Button>
              ) : null}
            </Row> */}
            <Row className="justify-content-end">
              {apiFunction != null ? (
                <Button className="w-25" type="submit" disabled={isLoading}>
                  {isLoading ? (
                    <Spinner as="span" animation="border" size="sm" />
                  ) : (
                    "Submit"
                  )}
                </Button>
              ) : null}
            </Row>
          </fieldset>
        </Form>
      </Row>
      {error != null ? <div>The error is: {error.message}</div> : null}
    </ClientFormStyles>
  );
}
