import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Col } from 'react-bootstrap'
import { Header } from './Messages'
import useGetClientMessages from '../../hooks/messageHooks/useGetClientMessages'
import usePostNewClient from '../../hooks/messageHooks/usePostClientMessage'
import MessageInput from './MessageInput';

interface MessageProps {
    $sent?: boolean | string;
}

interface MessageBubbleProps {
    $sent?: boolean | string;
}

const MainChat = styled(Col)`
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 90vh;
`;

const ContactName = styled.h5`
  margin-bottom: 0;
`;

const ContactNumber = styled.small`
  color: #6c757d;
`;

const ChatArea = styled.div`
  flex-grow: 1;
  padding: 1rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  scroll-snap-type: y mandatory;
`;

const Message = styled.div<MessageProps>`
  margin-bottom: 1rem;
  ${props => props.$sent && 'text-align: right;'}
`;

const MessageBubble = styled.div<MessageBubbleProps>`
  display: inline-block;
  padding: 0.5rem;
  border-radius: 0.5rem;
  max-width: 75%;
  ${props => props.$sent ? `
    background-color: #007bff;
    color: white;
  ` : `
    background-color: #f8f9fa;
  `}
`;

export default function ChatBox(
  {selectedClientData}:
  {selectedClientData: any}) {
  const { clientMessages, fetchClientMessages } = useGetClientMessages()
  const { postMessage } = usePostNewClient()
  const [currentMessage, setCurrentMessage ] = useState<string>("")

  useEffect(() => {
      fetchClientMessages(selectedClientData.clientId);
  }, [fetchClientMessages, selectedClientData]);

  const capitalizeWords = (input_str: string | null | undefined) => {
    if (input_str) {
      return input_str.toLocaleLowerCase().replace(/\b\w/g, (char: string) => char.toUpperCase());
    }
    return input_str
  };

  const handleNewMessage = () => {
    clientMessages.push({
      sender: "You",
      text: currentMessage,
      threadId: 0,
      timeStamp: "just now"
    })
    postMessage({
      clientId: selectedClientData.clientId,
      text: currentMessage,
      threadId: 0,
    })
    setCurrentMessage("")
  }

  return (
    <MainChat md={8}>
      <Header>
          <ContactName>{capitalizeWords(selectedClientData.name)}</ContactName>
          <ContactNumber>{selectedClientData.phoneNumber}</ContactNumber>
      </Header>
      {clientMessages !== null && clientMessages !== undefined ? (
      <ChatArea>
        {clientMessages?.map((message: any, index: number) => (
          <Message key={index} $sent={message.sender !== selectedClientData.name ? selectedClientData.name : undefined}>
              <MessageBubble $sent={message.sender !== selectedClientData.name ? selectedClientData.name : undefined}>
                  {message.text}
              </MessageBubble>
            </Message>
          ))}
      </ChatArea>
      ): <span></span>}
      <MessageInput handleNewMessage={handleNewMessage} currentMessage={currentMessage} setCurrentMessage={setCurrentMessage}/>
    </MainChat>
  )
}