import {Button } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
  

export default function ErrorModal({
  handleClose,
  error,
  message
}: {
  handleClose: () => void;
  error: string
  message: string
}) {
  

  return(
    <>
      <Modal.Header closeButton>
        <Modal.Title>{error}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div>
      Please reach out to your administrator for assistance with {message}
      </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Close</Button>
        </Modal.Footer>
    </>
  )
}