import { ThemeProvider } from "styled-components";
import GlobalStyle from "./styles/GlobalStyle";
import theme from "./styles/theme";
import "@fontsource/inter";
import "./App.css";
import LeftNavbar from "./components/page-level/LeftNavbar";
import Viewport from "./components/page-level/Viewport";
import CustomerLeftNavbar from "./components/page-level/CustomerLeftNavbar";
import CustomerViewport from "./components/page-level/CustomerViewport";
import Header from "./components/page-level/Header";
import Footer from "./components/page-level/Footer";
import { Route, Switch } from "wouter";
import FourOFour from "./components/common/FourOFour";
import Login from "./components/common/Login";
import { UrlPaths } from "./utilities/types";
import RegisterUser from "./components/common/RegisterUser";
import ProtectedRoute from "./components/tools/ProtectedRoute";

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Switch>
          <Route path={UrlPaths.Home}>
            <Login />
          </Route>
          <ProtectedRoute path={UrlPaths.Register}>
            <RegisterUser />
          </ProtectedRoute>
          <Route path="/notfound">
            <FourOFour />
          </Route>
          <ProtectedRoute path="/customer/*">
            <div className="page-layout">
              <CustomerLeftNavbar />
              <div className="main-content">
                <Header />
                <CustomerViewport />
                <Footer />
              </div>
            </div>
          </ProtectedRoute>
          <ProtectedRoute>
            <div className="page-layout">
              <LeftNavbar />
              <div className="main-content">
                <Header />
                <Viewport />
                <Footer />
              </div>
            </div>
          </ProtectedRoute>
        </Switch>
      </ThemeProvider>
    </>
  );
}

export default App;
