import styled from "styled-components";
import LogoSvg from "../../assets/logo trial-01.svg";

const StyledLogoContainer = styled.div`
  width: 20vw;
  height: 10%;
`;

export default function LogoContainer() {
  return (
    <StyledLogoContainer>
      <img src={LogoSvg} alt="logo" />
    </StyledLogoContainer>
  );
}
