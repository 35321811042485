import { useParams } from "wouter";
import { HTTPMethods, UrlPaths } from "../../utilities/types";
import useOneClientData from "../../hooks/clientHooks/useOneClientData";
import { useCallback, useEffect } from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import ClientForm from "./ClientForm";
import { TClientData } from "../../utilities/clientTypes";

export default function EditClient() {
  const params = useParams();
  const { oneClientData, clientData, isLoading, error } = useOneClientData();

  const clientID = params.id ?? "";

  useEffect(() => {
    oneClientData(clientID, HTTPMethods.GET);
  }, [oneClientData, clientID]);

  const sendEditClientData = useCallback(
    (clientData: TClientData) => {
      oneClientData(clientID, HTTPMethods.PUT, clientData);
    },
    [oneClientData, clientID]
  );

  const sendDeleteClient = useCallback(() => {
    oneClientData(clientID, HTTPMethods.DELETE);
  }, [oneClientData, clientID]);

  return (
    <Container fluid>
      <Row className="m-3 justify-content-center">
        <Row className="justify-content-center">
          <Col className="col-auto">
            <h3>Edit Old Client Information</h3>
          </Col>
        </Row>
        <ClientForm
          clientData={clientData}
          isLoading={isLoading}
          error={error}
          apiFunction={sendEditClientData}
        />
      </Row>
      <Button disabled={isLoading} onClick={sendDeleteClient}>
        {isLoading ? (
          <Spinner as="span" animation="border" size="sm" />
        ) : (
          "Delete"
        )}
      </Button>
      <Button href={UrlPaths.Clients}>Back</Button>
    </Container>
  );
}
