import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import useGetDownloadTemplateData from "../../hooks/templateHooks/useGetDownloadTemplate";

export default function DownloadAveryLabelModal({
  handleClose,
}: {
  handleClose: () => void;
}) {
  const { fetchDownloadTemplate } = useGetDownloadTemplateData();

  const [selectedTrustType, setSelectedTrustType] = useState<string>("");

  const handleTrustTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setSelectedTrustType(value);
  };

  const handleSubmit = () => {
    if (selectedTrustType) {
      console.log("Selected Trust Type for download:", selectedTrustType);
      fetchDownloadTemplate(selectedTrustType);
      handleClose();
    } else {
      alert("Please select a Trust Type");
    }
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Select Trust Type</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Check
              inline
              type="radio"
              label="CPT"
              value="Avery Labels CPT"
              name="trustType"
              onChange={handleTrustTypeChange}
            ></Form.Check>
            <Form.Check
              inline
              type="radio"
              label="SPT"
              value="Avery Labels SPT"
              name="trustType"
              onChange={handleTrustTypeChange}
            ></Form.Check>
          </Form.Group>
          <Button
            variant="primary"
            // disabled={deactivate}
            onClick={handleSubmit}
          >
            Download
          </Button>
        </Form>
      </Modal.Body>
    </>
  );
}
