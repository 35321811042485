const theme = {
  colors: {
    mainGreen: "#4C5637",
    mainLightGreen: "#656E51",
    mainGold: "#C18633",
    neutrals1: "#F5F4ED",
    neutrals2: "#EDE3D9",
  },
};

export default theme;
