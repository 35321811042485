import { InputGroup, Form, Button } from "react-bootstrap"

type SearchSettings = {
    inProgressSearchTerm: string,
    setInProgressSearchTerm: Function,
    setSearchTerm: Function,
    setPageNumber: Function,
    page: string,
}

export default function DataListSearch ({ inProgressSearchTerm, setInProgressSearchTerm, setSearchTerm, setPageNumber, page }: SearchSettings) {
    const updateSearchTerm = () => {
        if (inProgressSearchTerm === "") {
            setSearchTerm(null);
            setPageNumber(1);
        } else {
            setSearchTerm(inProgressSearchTerm);
            setPageNumber(1);
        }
    };

    return (
        <InputGroup style={{ paddingBottom: '10px', paddingTop: '10px'}}>
            <Form.Control
              id="fileSearch"
              type="text"
              placeholder={`Search ${page}...`}
              aria-label="search clients input"
              aria-describedby="fileSearch"
              value={inProgressSearchTerm}
              onChange={({ target: { value } }) =>
                setInProgressSearchTerm(value)
              }
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  updateSearchTerm()
                }
              }}
            />
            <Button onClick={()=> updateSearchTerm()}>Search</Button>
          </InputGroup>
    )
}
