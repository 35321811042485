import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { TFeeData, TTrustsData } from "../../../utilities/trustTypes";
import { getDefaultFeesData } from "../../../utilities/defaultDataTypes";

export const defaultFeeData: TFeeData = getDefaultFeesData();

export default function Fee({
  formData,
  setFormData,
  readOnly,
}: {
  formData: TTrustsData;
  setFormData: (trustsData: TTrustsData) => void;
  readOnly?: boolean;
}) {
  const updateCurrentFee = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    key: keyof TFeeData
  ) => {
    if (readOnly) return;
    let value: string | boolean = event.currentTarget.value;

    // const fees = formData.fees;

    if (value === "true") {
      value = true;
    } else if (value === "false") {
      value = false;
    }

    if (!formData.fees) return;

    const updatedFees = {
      ...formData.fees,
      [key]: value,
    };

    setFormData({
      ...formData,
      fees: updatedFees,
    });
  };
  return (
    <>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="trustDocsCost">
            <Form.Label>Trust Document Cost</Form.Label>
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control
                name="trustDocsCost"
                type="text"
                value={formData.fees?.trustDocsCost}
                onChange={(target) => updateCurrentFee(target, "trustDocsCost")}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="paid">
            <Form.Label>Amount Paid</Form.Label>
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control
                name="paid"
                type="text"
                value={formData.fees?.paid}
                onChange={(target) => updateCurrentFee(target, "paid")}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="balanceDue">
            <Form.Label>Balance Due</Form.Label>
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control
                name="balanceDue"
                type="text"
                value={formData.fees?.balanceDue}
                onChange={(target) => updateCurrentFee(target, "balanceDue")}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="dueToNotary">
            <Form.Label>Balance Due to Notary</Form.Label>
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control
                name="dueToNotary"
                type="text"
                value={formData.fees?.dueToNotary}
                onChange={(target) => updateCurrentFee(target, "dueToNotary")}
              />
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
    </>
  );
}
