import { useCallback, useMemo, useState, useRef } from "react";
import { headers } from "../authHeader";

export type TClientContactData = {
  id: number;
  fullname: string;
  phoneNumber: string;
};

const PAGE_SIZE = 10;

export default function useGetClientContactData() {
  const [isLoadingClientContacts, setIsLoadingClientContacts] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [cData, setClientData] = useState<Array<any> | null>(null);
  const [totalPages, setTotalPages] = useState<number>(0);
  const currentFetchId = useRef(0)

  const fetchData = useCallback(
    async (pageNumber: number, searchTerm: string | null) => {
      const fetchId = ++currentFetchId.current
      let url = `https://hometrustapi.azurewebsites.net/api/Clients?PageSize=${PAGE_SIZE}&PageNumber=${pageNumber}`;

      if (searchTerm != null) {
        url += "&SearchTerm=" + searchTerm;
      }

      setIsLoadingClientContacts(true);
      setError(null);

      try {
        const response = await fetch(url, {
          headers,
          method: "GET",
        });

        if (!response.ok) {
          throw new Error(
            "Server returned error code: " +
              response.status +
              " " +
              (await response.text())
          );
        }

        const responsejson = await response.json();

        if (fetchId === currentFetchId.current) {
          setClientData(responsejson.clients);
          const totalEntries: number = responsejson.metaData.totalEntries;
          setTotalPages(Math.ceil(totalEntries / PAGE_SIZE));
          setIsLoadingClientContacts(false);
        } else {
          console.log("Fetch Operation Expired")
        }
      } catch (error: any) {
        setError(error);
        setIsLoadingClientContacts(false);
      }
    },
    []
  );

  const clientContactData = useMemo(() => {
    if (cData == null) {
      return null;
    }
    return cData.map(
      (client: {
        id: number;
        firstName: string;
        lastName: string;
        phoneNumber: string;
      }): TClientContactData => {
        return {
          id: client.id,
          fullname: client.firstName + " " + client.lastName,
          phoneNumber: client.phoneNumber
        };
      }
    );
  }, [cData]);

  return { clientContactData, totalPages, isLoadingClientContacts, error, fetchData };
}
