import styled from "styled-components";
import Gradient from "../../assets/gradient.png";

const StyledGradientContainer = styled.div`
  position: relative;
  flex: 1;

  img {
    width: 100%;
    height: 100vh;
  }
`;

export default function GradientContainer() {
  return (
    <StyledGradientContainer>
      <img src={Gradient} alt="Gradient" />
    </StyledGradientContainer>
  );
}
