import { useCallback, useMemo, useState} from "react";
import { LegalDocTypes } from "../../utilities/types";
import { headers } from "../authHeader";

export type TFileData = {
  id: any;
  name: string;
  docType: LegalDocTypes;
  dateCreated: string;
  dateModified: string;
  category: string;
  size: number;
  notes: string;
};

const PAGE_SIZE = 10;

export default function useGetFileData() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [errorDownload, setErrorDownload] = useState<any>(null);
  const [cFileData, setClientFileData] = useState<Array<any> | null>(null);
  const [totalPages, setTotalPages] = useState<number>(0);


  const fetchDataFiles = useCallback(
    async (pageNumber: number, searchTerm: string | null, clientId: number) => {

      let URL = "https://hometrustapi.azurewebsites.net/api/ClientFiles?"
      let params = new URLSearchParams({
        clientId: `${clientId}`,
        PageNumber: `${pageNumber}`,
        PageSize: `${PAGE_SIZE}`,
      });

      let urlSearchTerm = ""
      if (searchTerm != null) {
        urlSearchTerm = "&SearchTerm=" + searchTerm;
      }

      setIsLoading(true);
      setError(null);

      try {
        const response = await fetch(URL + params + urlSearchTerm, {
          headers,
          method: "GET",
        });

        if (!response.ok) {
          throw new Error(
            "Server returned error code: " +
            response.status +
            " " +
            (await response.text())
          );
        }

        const responseData = await response.json();
        setClientFileData(Array.from(responseData.clientFiles) || null);
        const totalEntries: number = responseData.metaData.totalEntries;
        setTotalPages(Math.ceil(totalEntries / PAGE_SIZE));
      } catch (error: any) {
        setError(error);
      }
      setIsLoading(false);
    },
    []
  );

  const clientFileData = useMemo(() => {
    if (cFileData == null) {
      return null
    }
    return cFileData.map(
      (clientFile: {
        id: any;
        name: string;
        docType: LegalDocTypes;
        dateCreated: string;
        dateModified: string;
        category: string;
        size: number;
        notes: string;
      }): TFileData => {
        const dateCreated = new Date(clientFile.dateCreated);
        const dateModified = new Date(clientFile.dateModified);
        return {
          id: clientFile.id,
          name: clientFile.name,
          docType: LegalDocTypes.IMAGE,
          dateCreated: dateCreated.toLocaleDateString(),
          dateModified: dateModified.toLocaleDateString(),
          category: clientFile.category,
          size: clientFile.size,
          notes: clientFile.notes
        };
      }
    );
  }, [cFileData]);

  const downloadClientFile = useCallback(async (clientFileId: Number, filename: string) => {
    let url = `https://hometrustapi.azurewebsites.net/api/ClientFiles/${clientFileId}/download`

    setErrorDownload(null);

    try {
      await fetch(url, { headers, method: "GET" })
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }).catch(error => console.error('Error downloading file:', error));
    }catch (error: any) {
      setErrorDownload(error);
    }
  }, [])

  return { clientFileData, isLoading, error, fetchDataFiles, totalPages, downloadClientFile, errorDownload, setClientFileData};
}
