import { Redirect, Route, Switch } from "wouter";
import CustomerDashboard from "../dashboard/CustomerDashboard";
import { CustomerUrlPaths } from "../../utilities/types";
import ContactAgent from "../customers/ContactAgent";
import RequestService from "../customers/RequestService";
import Profile from "../customers/Profile";
import MyDocuments from "../customers/Documents";

export default function CustomerViewport() {
  return (
    <div className="viewport">
      <Switch>
        <Route path={CustomerUrlPaths.CustomerDashboard}>
          <CustomerDashboard />
        </Route>
        <Route path={CustomerUrlPaths.Documents}>
          <h2>My Documents</h2>
          <MyDocuments />
        </Route>
        <Route path={CustomerUrlPaths.Profile}>
          <h2>My Profile</h2>
          <Profile />
        </Route>
        <Route path={CustomerUrlPaths.RequestService}>
          <h2>Request a Service</h2>
          <RequestService />
        </Route>
        <Route path={CustomerUrlPaths.ContactAgent}>
          <h2>Contact My Agent</h2>
          <ContactAgent />
        </Route>
        <Route>
          <Redirect to="/notfound" />
        </Route>
      </Switch>
    </div>
  );
}
