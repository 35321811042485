import { useCallback, useState } from "react";
import { HTTPMethods, UrlPaths } from "../../utilities/types";
import { TTrustsData } from "../../utilities/trustTypes";
import { useLocation } from "wouter";
import { headers } from "../authHeader";

export default function useOneTrustData() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [trustsData, setTrustData] = useState<TTrustsData | null>(null);
  const [, setLocation] = useLocation();

  const oneTrustData = useCallback(
    async (id: string, method: HTTPMethods, trustsData?: TTrustsData) => {
      const url = "https://hometrustapi.azurewebsites.net/api/Trusts/" + id;

      setIsLoading(true);
      setError(null);

      try {
        let response = null;
        if (method === HTTPMethods.GET || method === HTTPMethods.DELETE) {
          response = await fetch(url, {
            headers,
            method,
          });
        }

        if (method === HTTPMethods.PUT) {
          response = await fetch(url, {
            headers,
            method,
            body: JSON.stringify(trustsData),
          });
        }

        if (response == null) {
          throw new Error("Response is null");
        }
        if (!response.ok) {
          throw new Error(
            "Server returned error code: " +
              response.status +
              " " +
              (await response.text())
          );
        }

        if (method === HTTPMethods.GET) {
          setTrustData(await response.json());
        }
        if (method === HTTPMethods.DELETE) {
          setLocation(UrlPaths.Trusts);
        }
      } catch (error: any) {
        setError(error);
        setIsLoading(false);
      }

      setIsLoading(false);
    },
    [setLocation]
  );

  return { oneTrustData, trustsData, isLoading, error };
}
