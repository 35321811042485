import { Button, Row, Container } from "react-bootstrap";
import ClientForm from "./ClientForm";
import { UrlPaths } from "../../utilities/types";
import { TClientData } from "../../utilities/clientTypes";

export default function ClientInfoTab({
  clientID,
  clientData,
  error,
}: {
  clientID: string;
  clientData: TClientData | null;
  error: any;
}) {
  return (
    <Container fluid>
      <Row>
        <ClientForm
          clientData={clientData}
          isLoading={false}
          error={error}
          readOnly
        />
      </Row>
      <Button href={UrlPaths.EditClient + clientID}>Edit</Button>
      <Button href={UrlPaths.Clients}>Back</Button>
    </Container>
  );
}
