import { useState } from "react";
import { headers } from "../authHeader";

export default function usePostNewFile() {
  const [isLoadingPost, setIsLoadingPost] = useState<boolean>(false);
  const [errorPost, setErrorPost] = useState<any>(null);

  const submitFile = async (fileData: FormData, destination: string) => {
    const url = "https://hometrustapi.azurewebsites.net" + destination;

    setIsLoadingPost(true);
    setErrorPost(null);

    try {
      const response = await fetch(url, {
        headers,
        method: "POST",
        body: fileData,
      });

      if (!response.ok) {
        throw new Error("Server returned error code: " + response.status);
      }
    } catch (error: any) {
      setErrorPost(error);
      setIsLoadingPost(false);
    }
    setIsLoadingPost(false);
  };

  return { submitFile, isLoadingPost, errorPost };
}
