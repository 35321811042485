import styled from "styled-components";
// import Badge from "@mui/material/Badge";
import NavDropdown from "react-bootstrap/NavDropdown";
import Button from "react-bootstrap/Button";
// import Stack from "@mui/material/Stack";
// import MailIcon from "@mui/icons-material/Mail";
// import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded";
import useLogout from "../../hooks/useLogout";

import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

// const StyledStack = styled(Stack)`
//   align-items: center;
// `;

// const StyledBadge = styled(Badge)`
//   color: #8a92a6;

//   span {
//     background-color: #656e51;
//   }
// `;

const StyledNavDropdown = styled(NavDropdown)`
  .dropdown-toggle::after {
    display: none;
  }
`;

export default function Header() {
  const logout = useLogout();
  const currentUser = localStorage.getItem("currentUser");
  const userInitials = localStorage.getItem("currentUserInitials");

  return (
    <Navbar
      className="justify-content-end"
      style={{ backgroundColor: "white", padding: "0px" }}
    >
      <Nav style={{ alignItems: "center" }}>
        {/* <Navbar.Text style={{ paddingLeft: "5px" }}>
          <StyledBadge badgeContent={0} variant="dot">
            <NotificationsRoundedIcon fontSize="small" />
          </StyledBadge>
        </Navbar.Text>
        <Navbar.Text style={{ paddingLeft: "5px" }}>
          <StyledStack spacing={1} direction="row">
            <StyledBadge badgeContent={4} color="secondary" variant="dot">
              <MailIcon fontSize="small" />
            </StyledBadge>
          </StyledStack>
        </Navbar.Text> */}
        <StyledNavDropdown
          title={
            <Button
              style={{
                borderRadius: "50%",
                width: "26px",
                height: "26px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "14px",
                color: "white",
                backgroundColor: "#8a92a6",
              }}
            >
              {userInitials}
            </Button>
          }
        >
          <NavDropdown.Item href="#logout" onClick={logout}>
            Logout
          </NavDropdown.Item>
        </StyledNavDropdown>
        <Navbar.Text style={{ paddingRight: "20px" }}>
          {currentUser}
        </Navbar.Text>
      </Nav>
    </Navbar>
  );
}
