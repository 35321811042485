import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/esm/Form";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import ButtonGroup from "react-bootstrap/esm/ButtonGroup";
// import useGetFileData from "../../hooks/useGetFileData";
import { CustomerUrlPaths } from "../../utilities/types";

export default function MyDocuments() {
  // const documentData = useGetFileData();

  return (
    <>
      <Row className="justify-content-center">
        <ButtonToolbar className="justify-content-between w-75 p-0">
          <Button
            variant="outline-secondary"
            size="sm"
            className=""
            href={CustomerUrlPaths.Profile}
          >
            + New Document
          </Button>
          <InputGroup>
            <Form.Control
              id="documentSearch"
              type="text"
              placeholder="Search documents..."
              aria-label="search documents input"
              aria-describedby="documentSearch"
            />
          </InputGroup>
          <ButtonGroup>
            <Button>{"<"}</Button>
            <Button>1</Button>
            <Button>2</Button>
            <Button>...</Button>
            <Button>{">"}</Button>
          </ButtonGroup>
        </ButtonToolbar>
        <Table bordered hover className="w-75">
          <thead>
            <tr>
              <th>Document Name</th>
              <th>Document Type</th>
              <th>Date</th>
              <th>View</th>
            </tr>
          </thead>
          <tbody>
            {/* {documentData.map((data) => (
              <tr>
                <th>{data.name}</th>
                <th>{data.docType}</th>
                <th>{data.date}</th>
                <th>
                  <Button>-</Button>
                </th>
              </tr>
            ))} */}
          </tbody>
        </Table>
      </Row>
    </>
  );
}
