import {Button } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
  

export default function NewAccountModal({
  handleClose,
}: {
  handleClose: () => void;
}) {
  

  return(
    <>
      <Modal.Header closeButton>
        <Modal.Title>Create Account</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div>
      Please reach out to your administrator for assistance with creating a new account.
      </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Close</Button>
        </Modal.Footer>
    </>
  )
}