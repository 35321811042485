import { useParams } from "wouter";
import { HTTPMethods, UrlPaths } from "../../utilities/types";
import { useCallback, useEffect } from "react";
import { Button, Container, Row, Spinner } from "react-bootstrap";
import useOneTrustData from "../../hooks/trustHooks/useOneTrustData";
import { TTrustsData } from "../../utilities/trustTypes";
import TrustForm from "./TrustForm";
import styled from "styled-components";

const StyledActionButtons = styled.div`
  margin: 18px;

  .btn:last-of-type {
    margin-left: 8px;
  }
`;

export default function EditTrust() {
  const params = useParams();
  const { oneTrustData, trustsData, isLoading, error } = useOneTrustData();

  const trustID = params.id ?? "";

  useEffect(() => {
    oneTrustData(trustID, HTTPMethods.GET);
  }, [oneTrustData, trustID]);

  const sendEditTrustData = useCallback(
    (trustsData: TTrustsData) => {
      oneTrustData(trustID, HTTPMethods.PUT, trustsData);
    },
    [oneTrustData, trustID]
  );

  const sendDeleteTrust = useCallback(() => {
    oneTrustData(trustID, HTTPMethods.DELETE);
  }, [oneTrustData, trustID]);

  return (
    <Container fluid>
      <Row className="m-3 justify-content-center">
        <TrustForm
          trustsData={trustsData}
          isLoading={isLoading}
          error={error}
          apiFunction={sendEditTrustData}
        />
      </Row>
      <StyledActionButtons>
        <Button disabled={isLoading} onClick={sendDeleteTrust}>
          {isLoading ? (
            <Spinner as="span" animation="border" size="sm" />
          ) : (
            "Delete"
          )}
        </Button>
        <Button href={UrlPaths.Trusts}>Back</Button>
      </StyledActionButtons>
    </Container>
  );
}
