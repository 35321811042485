import styled from 'styled-components';
import { Form, Button } from 'react-bootstrap';

const InputArea = styled(Form)`
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  bottom: 0px;
`;

const InputActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CharCount = styled.div`
  color: #6c757d;
  font-size: 0.875rem;
`;

export default function MessageInput(
    {handleNewMessage, currentMessage, setCurrentMessage} :
    {handleNewMessage: Function, setCurrentMessage: Function, currentMessage: string}) {

    return (
        <InputArea>
            <Form.Group className="mb-3">
                <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Type a message..."
                    value={currentMessage}
                    onChange={(e) => setCurrentMessage(e.target.value)}
                />
            </Form.Group>
            <InputActions>
                <div>
                    <Button variant="link" className="text-muted">
                        <i className="bi bi-paperclip"></i>
                    </Button>
                    <Button variant="link" className="text-muted">
                        <i className="bi bi-image"></i>
                    </Button>
                </div>
                <CharCount>
                </CharCount>
                <Button variant="primary" onClick={() => handleNewMessage()}>Send</Button>
            </InputActions>
        </InputArea>
    )
}