import Spinner from "react-bootstrap/esm/Spinner";
import useLogin from "../../hooks/useLogin";
import { Form, InputGroup } from "react-bootstrap";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";
import GradientContainer from "../design/Gradient";
import LogoContainer from "../design/LoginRegisterLogo";
import ErrorModal from "./ErrorModal";
import NewAccountModal from "./newAccountModal";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";

const StyledLoginContainer = styled.div`
  background-color: #ffffff;
  width: 100vw;
  height: 100vh;
  display: flex;
`;

const StyledFormContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    line-height: 42.9px;
    color: #232d42;
  }

  h2 {
    font-weight: bold;
    line-height: 42.9px;
  }

  h6:first-of-type {
    color: #8a92a6;
  }

  h6 {
    color: #232d42;
    line-height: 28px;
    margin-bottom: 24px;

    a {
      color: #8a92a6;
      text-decoration: none;

      &:hover {
        color: #3a57e8;
        text-decoration: underline;
      }
    }
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  width: 32vw;
  justify-content: flex-start;
`;

const StyledFormLabel = styled(Form.Label)`
  color: #8a92a6;
`;

const StyledFormControl = styled(Form.Control)`
  border-radius: 4px;
  border: 1px solid #bccad7;
  padding: 8px 16px;
  width: 436px;
`;

const StyledInputGroup = styled(InputGroup)`
  border: none;
  display: flex;
`;

const StyledPasswordButton = styled.button.attrs({
  className: "btn btn-primary",
})`
  background: white;
  border: 1px solid #bccad7;
  border-left: none;
  margin-left: -1px;
  border-radius: 0 4px 4px 0;
  outline: none;
  box-shadow: none;

  &:hover {
    --bs-btn-hover-color: white;
    --bs-btn-hover-border-color: white;
    background: white;
    border: 1px solid #bccad7;
    border-left: none;
    z-index: 1;
  }

  &:active {
    --bs-btn-active-bg: white;
    --bs-btn-active-border-color: #bccad7;
    background: white;
    border: 1px solid #bccad7;
    border-left: none;
    margin-left: -1px;
  }

  svg {
    color: black;

    &:hover {
      color: #4c5367;
    }

    &:active {
      color: #4c5367;
    }
  }
`;

const LoginOptions = styled.div`
  display: flex;
  justify-content: space-between;

  .form-check {
    color: #8a92a6;
    line-height: 28px;
  }

  .form-check-input {
    border: 1px solid #bccad7;
    box-shadow: 0px 2px 4px 0px #3b8aff40;
  }

  a {
    color: #bccad7;
    line-height: 28px;
    text-decoration: none;

    &:hover {
      color: #3a57e8;
      text-decoration: underline;
    }
  }
`;
const ButtonBar = styled.div`
  display: flex;
  justify-content: center;
`;

const SignInButton = styled.button`
  background-color: #c18633;
  color: #ffffff;
  border-radius: 4px;
  border: none;
  padding: 8px 24px 8px 24px;
  width: 40%;
`;

const ModalButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
`;

const StyledErrorMessage = styled.div`
  color: red;
  margin-bottom: 10px;
  text-align: center;
`;

export default function Login() {
  const { sendLogin, isLoading, error } = useLogin();
  const [userName, setUserName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showAccountModal, setShowAccountModal] = useState<boolean>(false);

  useEffect(() => {
    const savedUserName = localStorage.getItem("userName");
    if (savedUserName) {
      setUserName(savedUserName);
      setRememberMe(true);
    }
  }, []);

  const toggleShowPassword = () => setShowPassword((p) => !p);

  const onLogin = async (e: any) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    const loginData = Object.fromEntries(formData.entries()) as {
      userName: string;
      password: string;
    };
    try {
      await sendLogin(loginData);

      if (rememberMe) {
        localStorage.setItem("userName", loginData.userName);
      } else {
        localStorage.removeItem("userName");
        sessionStorage.setItem("userName", loginData.userName);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const toggleShow = () => setShowModal((p) => !p);

  const handleOpenModal = () => {
    toggleShow();
  };

  const toggleShowAccountModal = () => setShowAccountModal((p) => !p);

  const handleOpenAccountModal = () => {
    toggleShowAccountModal();
  };

  return (
    <>
      <StyledLoginContainer>
        <StyledFormContainer>
          <LogoWrapper>
            <LogoContainer />
          </LogoWrapper>
          <h2 className="mb-3">Sign In</h2>
          <h6>Sign in to stay connected.</h6>
          <Form onSubmit={onLogin}>
            <Form.Group className="mb-3" controlId="userNameLogin">
              <StyledFormLabel>Username</StyledFormLabel>
              <StyledFormControl
                required
                type="text"
                name="userName"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                placeholder="Enter Username"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="userPassword">
              <StyledFormLabel>Password</StyledFormLabel>
              <StyledInputGroup>
                <StyledFormControl
                  required
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <StyledPasswordButton
                  onClick={(e) => {
                    e.preventDefault();
                    toggleShowPassword();
                  }}
                >
                  {showPassword ? <IoEyeOffOutline /> : <IoEyeOutline />}
                </StyledPasswordButton>
              </StyledInputGroup>
            </Form.Group>
            {error != null ? (
              <StyledErrorMessage>
                Please provide a valid username or password
              </StyledErrorMessage>
            ) : null}
            <LoginOptions>
              <div>
                <Form.Group className="mb-3" controlId="">
                  <Form.Check
                    type="checkbox"
                    checked={rememberMe}
                    onChange={(e) => setRememberMe(e.target.checked)}
                    label="Remember Me?"
                  />
                </Form.Group>
              </div>
              <ModalButton
                type="button"
                style={{ cursor: "hover" }}
                onClick={handleOpenModal}
              >
                Forgot Password
              </ModalButton>
            </LoginOptions>
            <ButtonBar>
              <SignInButton className="mb-3" type="submit" disabled={isLoading}>
                {isLoading ? (
                  <Spinner as="span" animation="border" size="sm" />
                ) : (
                  "Sign In"
                )}
              </SignInButton>
            </ButtonBar>
          </Form>
          <h6>
            Don't have an account?{" "}
            {/* <a href={UrlPaths.Register}>Click here to sign up.</a> */}
            <ModalButton
              type="button"
              style={{ cursor: "hover" }}
              onClick={handleOpenAccountModal}
            >
              Click here to sign up.
            </ModalButton>
          </h6>
        </StyledFormContainer>
        <GradientContainer />
      </StyledLoginContainer>
      <Modal
        centered
        show={showModal}
        onHide={toggleShow}
        size="sm"
        contentClassName="generate-template-modal"
      >
        <ErrorModal
          handleClose={toggleShow}
          error="Reset Password"
          message="resetting your password."
        />
      </Modal>
      <Modal
        centered
        show={showAccountModal}
        onHide={toggleShowAccountModal}
        size="sm"
        contentClassName="generate-template-modal"
      >
        <NewAccountModal handleClose={toggleShowAccountModal} />
      </Modal>
    </>
  );
}
