import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { CustomerUrlPaths } from "../../utilities/types";
import Stack from "react-bootstrap/Stack";

export default function CustomerDashboard() {
  return (
    <>
      <Container>
        <Stack gap={4} className="mb-5">
          <Stack
            direction="horizontal"
            gap={5}
            className="justify-content-center"
          >
            <Button
              href={CustomerUrlPaths.Documents}
              variant="light"
              size="lg"
              className="w-25"
            >
              My Documents
            </Button>
            <Button
              href={CustomerUrlPaths.RequestService}
              variant="light"
              size="lg"
              className="w-25"
            >
              Request a Service
            </Button>
          </Stack>
          <Stack
            direction="horizontal"
            gap={5}
            className="justify-content-center"
          >
            <Button
              href={CustomerUrlPaths.Profile}
              variant="light"
              size="lg"
              className="w-25"
            >
              My Profile
            </Button>
            <Button
              href={CustomerUrlPaths.ContactAgent}
              variant="light"
              size="lg"
              className="w-25"
            >
              Contact My Agent
            </Button>
          </Stack>
        </Stack>
      </Container>
    </>
  );
}
