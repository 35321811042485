import { Form, Spinner, Tooltip, OverlayTrigger } from "react-bootstrap";
import { UrlPaths } from "../../utilities/types";
import useUserRegister from "../../hooks/useUserRegister";
import styled from "styled-components";
import GradientContainer from "../design/Gradient";
import LogoContainer from "../design/LoginRegisterLogo";
import { useEffect, useState } from "react";

const StyledRegisterContainer = styled.div`
  background-color: #ffffff;
  width: 100vw;
  height: 100vh;
  display: flex;
`;

const StyledFormContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 30px;
  margin-right: 30px;

  h1 {
    line-height: 42.9px;
    margin-bottom: 10px;
    color: #232d42;
  }

  h2 {
    font-weight: bold;
    line-height: 42.9px;
  }

  h6:first-of-type {
    color: #8a92a6;
  }

  h6 {
    color: #232d42;
    line-height: 28px;
    margin-bottom: 24px;

    a {
      color: #8a92a6;
      text-decoration: none;

      &:hover {
        color: #3a57e8;
        text-decoration: underline;
      }
    }
  }

  .form-check {
    display: flex;
    justify-content: center;
    color: #8a92a6;
  }

  .form-check-input {
    border: 1px solid #bccad7;
    box-shadow: 0px 2px 4px 0px #3b8aff40;
    margin-right: 10px;
  }
`;

const RegisterLogo = styled.div`
  display: flex;
  width: 50vw;
  justify-content: flex-start;
  padding-left: 50px;
`;

const StyledFormWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
`;

const StyledFormLabel = styled(Form.Label)`
  color: #8a92a6;
`;

const StyledFormControl = styled(Form.Control)`
  border-radius: 4px;
  border: 1px solid #bccad7;
  padding: 8px 16px;
  width: 300px;

  &::placeholder {
    color: #8a92a6;
  }
`;

const ButtonBar = styled.div`
  display: flex;
  justify-content: center;
`;

const SignUpButton = styled.button`
  background-color: #c18633;
  color: #ffffff;
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  border-radius: 4px;
  border: none;
  padding: 8px 24px 8px 24px;
  width: 28%;
`;

const PasswordToolTip = (
  <Tooltip id="password-tooltip">
    Password must include 1 upper and lower case letter, 1 number, 1 symbol, and
    be a minimum of 6 characters.
  </Tooltip>
);

export default function RegisterUser() {
  const { sendRegister, isLoading, error } = useUserRegister();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    userName: "",
    email: "",
    password: "",
  });
  const [agreement, setAgreement] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  const onRegister = (e: any) => {
    e.preventDefault();
    // const formData = new FormData(e.target);

    // const loginData = Object.fromEntries(formData.entries()) as {
    //   firstName: string;
    //   lastName: string;
    //   userName: string;
    //   email: string;
    //   password: string;
    // };

    sendRegister(formData);
  };

  useEffect(() => {
    const isValid =
      formData.firstName.trim().length > 0 &&
      formData.lastName.trim().length > 0 &&
      formData.phone.trim().length > 0 &&
      formData.userName.trim().length > 0 &&
      formData.email.trim().length > 0 &&
      formData.password.trim().length > 0 &&
      agreement;
    setIsFormValid(isValid);
  }, [formData, agreement]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  return (
    <StyledRegisterContainer>
      <StyledFormContainer>
        <RegisterLogo>
          <LogoContainer />
        </RegisterLogo>
        <h2 className="mb-3">Sign Up</h2>
        <h6 className="mb-3">Create your HomeTrust account</h6>
        <Form onSubmit={onRegister}>
          <StyledFormWrapper>
            <Form.Group controlId="firstNameRegister">
              <StyledFormLabel>First name</StyledFormLabel>
              <StyledFormControl
                type="text"
                name="firstName"
                // placeholder="Enter first name"
                value={formData.firstName}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="lastNameRegister">
              <StyledFormLabel>Last name</StyledFormLabel>
              <StyledFormControl
                type="text"
                name="lastName"
                // placeholder="Enter last name"
                value={formData.lastName}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="userPhoneRegister">
              <StyledFormLabel>Phone No.</StyledFormLabel>
              <StyledFormControl
                type="text"
                name="phone"
                placeholder="(xxx) xxx-xxxx"
                value={formData.phone}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="userEmailRegister">
              <StyledFormLabel>Email</StyledFormLabel>
              <StyledFormControl
                type="email"
                name="email"
                placeholder="name@domain.com"
                value={formData.email}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="userNameRegister">
              <StyledFormLabel>Username</StyledFormLabel>
              <StyledFormControl
                type="text"
                name="userName"
                // placeholder="Enter username"
                value={formData.userName}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="userPasswordRegister">
              <StyledFormLabel>Password</StyledFormLabel>
              <OverlayTrigger overlay={PasswordToolTip}>
                <StyledFormControl
                  type="password"
                  name="password"
                  // placeholder="Enter password"
                  value={formData.password}
                  onChange={handleChange}
                />
              </OverlayTrigger>
            </Form.Group>
          </StyledFormWrapper>
          <Form.Group className="mb-3" controlId="">
            <Form.Check
              type="checkbox"
              checked={agreement}
              onChange={(e) => setAgreement(e.target.checked)}
              label="I agree with the terms of use"
            />
          </Form.Group>
          {error != null ? <div>The error is: {error.message}</div> : null}
          <ButtonBar>
            <SignUpButton
              className="mb-3"
              type="submit"
              disabled={!isFormValid || isLoading}
            >
              {isLoading ? (
                <Spinner as="span" animation="border" size="sm" />
              ) : (
                "Sign Up"
              )}
            </SignUpButton>
          </ButtonBar>
        </Form>
        <h6>
          Already have an account? <a href={UrlPaths.Home}>Sign in</a>
        </h6>
      </StyledFormContainer>
      <GradientContainer />
    </StyledRegisterContainer>
  );
}
