import { useEffect, useState } from "react";
import { Container, Button, Spinner } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { TFileData, UploadDestinations } from "../../utilities/types";

const defaultFileData: TFileData = {
  id: 0,
  clientId: 0,
  name: "",
  type: "",
  category: "",
  size: 0,
  notes: "",
  file: "",
};

export default function FileUpload({ fileData, isLoading, error, apiFunction, clientId, destination }: {
  fileData: TFileData | null;
  isLoading: boolean;
  error: any;
  clientId: number;
  destination: keyof typeof UploadDestinations
  apiFunction?: (formData: FormData, destination: string) => void;
}) {
  const [showFileUpload, setShowFileUpload] = useState<boolean>(false);
  const [formData, setFormData] = useState<TFileData>(defaultFileData);

  useEffect(() => {
    if (fileData != null) {
      setFormData(fileData);
      console.log("fileData set in useEffect:", fileData);
    }
  }, [fileData]);

  function getDestination(key: keyof typeof UploadDestinations): string {
    return UploadDestinations[key];
  }

  const toggleUploadButton = () => {
    setShowFileUpload((prev) => !prev);
  };

  const onFormSubmit = (e: any) => {
    e.preventDefault();

    const target = e.target as typeof e.target & {
      fileUpload: { files: FileList };
    };

    const file = target.fileUpload.files[0];
    console.log("Selected File", file);

    if (!file) {
      alert("No File Selected");
      return;
    }

    const newFileData: TFileData = {
      ...formData,
      clientId: clientId,
      name: file.name,
      category: formData.category,
      type: file.type,
      notes: formData.notes,
      size: file.size,
      file: file,
    };

    console.log("New file data:", newFileData);

    const formDataToPost = new FormData();
    formDataToPost.append("ClientId", newFileData.clientId.toString());
    formDataToPost.append("Notes", newFileData.notes);
    formDataToPost.append("Category", newFileData.category);
    formDataToPost.append("File", file);
    formDataToPost.forEach((value, key) => {
      console.log(key + ": " + value);
    });

    if (apiFunction) {
      const uploadDestination = getDestination(destination);
      apiFunction(formDataToPost, uploadDestination);
    } else {
      console.error("apiFunction is not defined");
    }
  };

  return (
    <>
      <Button
        onClick={toggleUploadButton}
        disabled={isLoading}
        className="mb-3"
      >
        {showFileUpload ? "Cancel Upload" : "Upload Template"}
      </Button>

      <Container>
        {showFileUpload && (
          <Form onSubmit={onFormSubmit} aria-disabled>
            <Form.Group className="mb-3" controlId="newFileCateogry">
              <Form.Label>Category</Form.Label>
              <Form.Control
                name="category"
                type="text"
                value={formData.category}
                onChange={({ target: { value } }) =>
                  setFormData({ ...formData, category: value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="newFileNotes">
              <Form.Label>Notes</Form.Label>
              <Form.Control
                name="notes"
                as="textarea"
                rows={3}
                value={formData.notes}
                onChange={({ target: { value } }) =>
                  setFormData({ ...formData, notes: value })
                }
              />
            </Form.Group>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Control type="file" name="fileUpload" />
            </Form.Group>
            <Button type="submit" disabled={isLoading}>
              {isLoading ? (
                <Spinner as="span" animation="border" size="sm" />
              ) : (
                "Upload"
              )}
            </Button>
          </Form>
        )}
      </Container>
      {error != null ? <div>The error is: {error.message}</div> : null}
    </>
  );
}
