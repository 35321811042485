import { useEffect } from 'react';
import styled from "styled-components";
import { Col, Image, ListGroup } from 'react-bootstrap';
import { Header } from './Messages'
import DataListSearchDropdown from "./ListSearchDropdown";
import useGetClientThreads from '../../hooks/messageHooks/useGetThreads'

const ThreadList = styled(ListGroup)`
    display: flex;
`;

const ThreadItem = styled(ListGroup.Item)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 0.2s ease;
    cursor: pointer;

    &:hover {
        background-color: #f0f0f0;
        box-shadow: 0 2px 5px rgba(0,0,0,0.1);
        color: #007bff; // Change text color to blue on hover
    }
`

const ChatSidebar = styled(Col)`
  padding: 0;
  border-right: 1px solid #dee2e6;
  height: 85vh;
  overflow-y: auto;
`;

const MessageContent = styled.div`
  display: flex;
  align-items: center;
`;

const MessageInfo = styled.div`
  text-align: end;
`;

export default function MessageSidebar({ setClientId }:{ setClientId: Function }) {
  const { clientThreads, fetchThreadData } = useGetClientThreads()

  useEffect(() => {
    fetchThreadData(null);
  }, [fetchThreadData]);

  const createThead = (clientData: any) => {
    setClientId({
      clientId: clientData.id,
      name: clientData.fullname,
      phoneNumber: clientData.phoneNumber,
    })
  }

  const capitalizeWords = (input_str: string | null) => {
    if (input_str) {
      return input_str.toLocaleLowerCase().replace(/\b\w/g, (char: string) => char.toUpperCase());
    }
    return 'N/A'
  };

  const handleClick = (thread: any) => {
    setClientId({
      clientId: thread.clientContact.clientId,
      name: thread.clientContact.name,
      phoneNumber: thread.clientContact.phoneNumber,
    })
  }

  return (
      <ChatSidebar md={4}>
        <Header>
          <DataListSearchDropdown
            createThread={createThead}
            page={"Clients"}
          />
        </Header>
        <ThreadList variant="flush">
          {clientThreads?.map((thread, index: number) => (
              <ThreadItem key={index} onClick={()=>handleClick(thread)}>
                <MessageContent>
                  <Image src="https://via.placeholder.com/40" roundedCircle className="me-3" />
                  <div>
                    <div>{capitalizeWords(thread.clientContact.name)}</div>
                    <small className="text-muted">{thread.messages[thread.messages.length - 1].text.substring(0, 20)}...</small>
                  </div>
                </MessageContent>
                <MessageInfo>
                  <small className="text-muted d-block">{new Date(thread.messages[thread.messages.length - 1].timestamp).toLocaleString()}</small>                  {thread.numInternalUnread >= 0 ? <span className="badge bg-primary rounded-pill">{thread.numInternalUnread}</span> : <span className="text-muted">Read</span>}
                </MessageInfo>
              </ThreadItem>
            ))}
        </ThreadList>
      </ChatSidebar>
    )
}