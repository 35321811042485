import { useCallback, useMemo, useState } from "react";
import { LegalDocTypes } from "../../utilities/types";
import { headers } from "../authHeader";

export type TTemplateData = {
  id: number;
  clientId: number;
  name: string;
  fullName?: string;
  docType: LegalDocTypes;
  dateCreated: string;
  dateModified: string;
  category: string;
  size: number;
  notes: string;
};

const PAGE_SIZE = 10;

export default function useGetTemplateData(useMockData: boolean) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [errorDownload, setErrorDownload] = useState<any>(null);
  const [tFileData, setTemplateFileData] = useState<Array<any> | null>(null);
  const [totalPages, setTotalPages] = useState<number>(0);

  const fetchTemplates = useCallback(
    async (
      pageNumber: number,
      searchTerm: string | null,
      clientId: number,
      orderBy: string | null,
      ascending: boolean | null
    ) => {
      let URL = "https://hometrustapi.azurewebsites.net/api/Templates?";
      let params = new URLSearchParams({
        clientId: `${clientId}`,
        PageNumber: `${pageNumber}`,
        PageSize: `${PAGE_SIZE}`,
      });

      let urlSearchTerm = "";
      if (searchTerm != null) {
        urlSearchTerm = "&SearchTerm=" + searchTerm;
      }
      let orderByField = "";
      if (orderBy != null && ascending != null) {
        orderByField = "&OrderBy=" + orderBy + "&Ascending=" + ascending;
      }

      setIsLoading(true);
      setError(null);

      try {
        const response = await fetch(
          URL + params + urlSearchTerm + orderByField,
          {
            headers,
            method: "GET",
          }
        );

        if (!response.ok) {
          throw new Error(
            "Server returned error code: " +
              response.status +
              " " +
              (await response.text())
          );
        }

        const responseData = await response.json();
        if (!useMockData) {
          setTemplateFileData(Array.from(responseData.templateFiles) || null);
        } else {
          setTemplateFileData(mockTemplates);
        }

        const totalEntries: number = responseData.metaData.totalEntries;
        setTotalPages(Math.ceil(totalEntries / PAGE_SIZE));
      } catch (error: any) {
        setError(error);
      }
      setIsLoading(false);
    },
    // eslint-disable-next-line
    []
  );

  const templateFileData = useMemo(() => {
    if (tFileData == null) {
      return null;
    }
    return tFileData.map(
      (templateFile: {
        id: any;
        name: string;
        clientId: number;
        docType: LegalDocTypes;
        dateCreated: string;
        dateModified: string;
        category: string;
        size: number;
        notes: string;
      }): TTemplateData => {
        const dateCreated = new Date(templateFile.dateCreated);
        const dateModified = new Date(templateFile.dateModified);
        return {
          id: templateFile.id,
          name: templateFile.name,
          clientId: templateFile.clientId,
          docType: templateFile.docType,
          dateCreated: dateCreated.toLocaleDateString(),
          dateModified: dateModified.toLocaleDateString(),
          category: templateFile.category,
          size: templateFile.size,
          notes: templateFile.notes,
        };
      }
    );
  }, [tFileData]);

  const downloadTemplateFile = useCallback(
    async (clientFileId: Number, filename: string) => {
      let url = `https://hometrustapi.azurewebsites.net/api/Templates/${clientFileId}/download`;

      setErrorDownload(null);

      try {
        await fetch(url, { headers, method: "GET" })
          .then((response) => response.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
          })
          .catch((error) => console.error("Error downloading file:", error));
      } catch (error: any) {
        setErrorDownload(error);
      }
    },
    []
  );

  const mockTemplates = [
    {
      id: 1,
      name: "Avery Labels",
      category: "WORD",
      trustId: "1",
      docType: LegalDocTypes.TEXT,
      dateCreated: "2024-07-16T15:47:23.753",
      dateModified: "2024-07-16T15:47:23.753",
      size: "1234",
      notes: "Notes",
    },
    {
      id: 2,
      name: "Vial of Life",
      category: "PDF",
      trustId: "1",
      docType: LegalDocTypes.PDF,
      dateCreated: "2024-07-16T15:47:23.753",
      dateModified: "2024-07-16T15:47:23.753",
      size: "1234",
      notes: "Notes",
    },
    {
      id: 3,
      name: "Binder Basics",
      category: "PDF",
      trustId: "1",
      docType: LegalDocTypes.PDF,
      dateCreated: "2024-07-16T15:47:23.753",
      dateModified: "2024-07-16T15:47:23.753",
      size: "1234",
      notes: "Notes",
    },
    {
      id: 4,
      name: "SPT",
      category: "WORD",
      trustId: "1",
      docType: LegalDocTypes.TEXT,
      dateCreated: "2024-07-16T15:47:23.753",
      dateModified: "2024-07-16T15:47:23.753",
      size: "1234",
      notes: "Notes",
    },
    {
      id: 5,
      name: "CPT",
      category: "WORD",
      trustId: "1",
      docType: LegalDocTypes.TEXT,
      dateCreated: "2024-07-16T15:47:23.753",
      dateModified: "2024-07-16T15:47:23.753",
      size: "1234",
      notes: "Notes",
    },
    {
      id: 6,
      name: "TA",
      category: "WORD",
      trustId: "1",
      docType: LegalDocTypes.TEXT,
      dateCreated: "2024-07-16T15:47:23.753",
      dateModified: "2024-07-16T15:47:23.753",
      size: "1234",
      notes: "Notes",
    },
    {
      id: 7,
      name: "Trust Certificate",
      category: "WORD",
      trustId: "1",
      docType: LegalDocTypes.TEXT,
      dateCreated: "2024-07-16T15:47:23.753",
      dateModified: "2024-07-16T15:47:23.753",
      size: "1234",
      notes: "Notes",
    },
    {
      id: 8,
      name: "Trust Transfer Deed",
      category: "WORD",
      trustId: "1",
      docType: LegalDocTypes.TEXT,
      dateCreated: "2024-07-16T15:47:23.753",
      dateModified: "2024-07-16T15:47:23.753",
      size: "1234",
      notes: "Notes",
    },
    {
      id: 9,
      name: "Love Letter",
      category: "WORD",
      trustId: "1",
      docType: LegalDocTypes.TEXT,
      dateCreated: "2024-07-16T15:47:23.753",
      dateModified: "2024-07-16T15:47:23.753",
      size: "1234",
      notes: "Notes",
    },
  ];

  return {
    templateFileData,
    isLoading,
    error,
    fetchTemplates,
    totalPages,
    downloadTemplateFile,
    errorDownload,
    setTemplateFileData,
  };
}
