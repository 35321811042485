import {
  TDecedentData,
  TFeeData,
  TRealEstateData,
  TSuccessorData,
  TTrustData,
  TTrusteeData,
  TTrustsData,
} from "./trustTypes";

export const getDefaultTrustsData = (): TTrustsData => ({
  trust: {
    id: 0,
    type: "",
    name: "",
    // aka: "",
    date: "",
    restated: false,
    year: "",
    familyName: "",
    isSpecialNeeds: false,
    specialNeedsInstructions: "",
    perStirpesOrCapita: "",
    children: "",
    childrenInstructions: "",
    remainderBeneficiaries: "",
    omittedPersons: "",
    financialInstitutions: "",
    directive1: "",
    directive2: "",
    directive3: "",
    directive4: "",
    directive5: "",
    meetingDate: "",
    numHeirs: "",
    heir1: "",
    heir2: "",
    heir3: "",
  },
  trustees: [],
  decedent: {
    id: 0,
    trustId: 0,
    type: "",
    fullName: "",
    lastName: "",
    akaNee: "",
    pronoun: "",
    fullAddress: "",
    city: "",
    county: "",
    phone: "",
    email: "",
    dob: "",
    ssn: "",
    ein: "",
    relationship: "",
    age: "",
    dod: "",
    day41: "",
    yearsInResidence: "",
    driverLicenseNumber: "",
    driverLicenseExpirationDate: "",
  },
  successors: [],
  realEstate: {
    id: 0,
    trustId: 0,
    apn: "",
    fullAddress: "",
    street: "",
    city: "",
    state: "",
    zip: "",
    county: "",
    grantor1: "",
    grantor2: "",
    grantorTitle: "",
    description: "",
    signingDate: "",
    signingCity: "",
    signingCounty: "",
    notaryName: "",
    notaryPhone: "",
    realtorName: "",
    realtorCompany: "",
    realtorPhone: "",
    documentNumber: "",
  },
  fees: {
    id: 0,
    trustId: 0,
    trustDocsCost: "",
    paid: "",
    balanceDue: "",
    dueToNotary: "",
  },
});

export const getDefaultTrustData = (): TTrustData => ({
  id: 0,
  type: "",
  name: "",
  // aka: "",
  date: "",
  restated: false,
  year: "",
  familyName: "",
  isSpecialNeeds: false,
  specialNeedsInstructions: "",
  perStirpesOrCapita: "",
  children: "",
  childrenInstructions: "",
  remainderBeneficiaries: "",
  omittedPersons: "",
  financialInstitutions: "",
  directive1: "",
  directive2: "",
  directive3: "",
  directive4: "",
  directive5: "",
  meetingDate: "",
  numHeirs: "",
  heir1: "",
  heir2: "",
  heir3: "",
});

export const getDefaultTrusteeData = (): TTrusteeData => ({
  id: 0,
  trustId: 0,
  type: "",
  fullName: "",
  lastName: "",
  akaNee: "",
  // aka: "",
  // nee: "",
  pronoun: "",
  fullAddress: "",
  street: "",
  city: "",
  state: "",
  zip: "",
  phone: "",
  email: "",
  dob: "",
  ssn: "",
  relationship: "",
  age: "",
  dod: "",
  day41: "",
  yearsInResidence: "",
  driverLicenseNumber: "",
  driverLicenseExpirationDate: "",
});

export const getDefaultDecedentData = (): TDecedentData => ({
  id: 0,
  trustId: 0,
  type: "Decedent",
  fullName: "",
  lastName: "",
  akaNee: "",
  // aka: "",
  // nee: "",
  pronoun: "",
  fullAddress: "",
  city: "",
  county: "",
  phone: "",
  email: "",
  dob: "",
  ssn: "",
  ein: "",
  relationship: "",
  age: "",
  dod: "",
  day41: "",
  yearsInResidence: "",
  driverLicenseNumber: "",
  driverLicenseExpirationDate: "",
});

export const getDefaultSuccessorData = (): TSuccessorData => ({
  id: 0,
  trustId: 0,
  type: "",
  fullName: "",
  lastName: "",
  akaNee: "",
  // aka: "",
  // nee: "",
  fullAddress: "",
  phone: "",
  email: "",
  dob: "",
  ssn: "",
  relationship: "",
  age: "",
  dod: "",
  day41: "",
  yearsInResidence: "",
});

export const getDefaultRealEstateData = (): TRealEstateData => ({
  id: 0,
  trustId: 0,
  apn: "",
  fullAddress: "",
  street: "",
  city: "",
  state: "",
  zip: "",
  county: "",
  grantor1: "",
  grantor2: "",
  grantorTitle: "",
  description: "",
  signingDate: "",
  signingCity: "",
  signingCounty: "",
  notaryName: "",
  notaryPhone: "",
  realtorName: "",
  realtorCompany: "",
  realtorPhone: "",
  documentNumber: "",
});
export const getDefaultFeesData = (): TFeeData => ({
  id: 0,
  trustId: 0,
  trustDocsCost: "",
  paid: "",
  balanceDue: "",
  dueToNotary: "",
});
