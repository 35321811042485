import {
  Row,
  Table,
  Button,
  ButtonToolbar,
  Col,
  Container,
} from "react-bootstrap";
import useGetClientData, {
  TClientData,
} from "../../hooks/clientHooks/useGetClientData";
import { UrlPaths } from "../../utilities/types";
import { useEffect, useState } from "react";
import styled from "styled-components";
import DataListPagination from "../tools/ListPagination";
import DataListSearch from "../tools/ListSearch";

const StyledContainer = styled(Container)`
  width: 85vw;
  border-radius: 8px;
  /* background-color: #ffffff; */

  h3 {
    border: none;
  }
`;

const ClientTable = styled(Table).attrs(() => ({
  className: "w-100",
}))`
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 8px;
  overflow: hidden;

  th,
  td {
    padding: 10px 14px;
    border-bottom: 1px solid #e0e0e0;
  }

  tr:last-child {
    th,
    td {
      border-bottom: none;
    }
  }

  thead th {
    background-color: #f5f5f5;
    font-weight: bold;
    text-align: left;
  }

  thead th {
    text-align: center;
  }

  tbody tr {
    cursor: pointer;
  }

  th {
    text-align: center;
  }

  th:nth-child(1) {
    width: 20%;
    text-align: left;
  }
  th:nth-child(2) {
    width: 20%;
  }
  th:nth-child(3) {
    width: 10%;
  }
  th:nth-child(4) {
    width: 5%;
  }
  th:nth-child(5) {
    width: 10%;
  }
  th:nth-child(5) {
    width: 10%;
  }
  th:nth-child(5) {
    width: 10%;
  }
  th:nth-child(5) {
    width: 10%;
  }

  .button-container {
    display: flex;
    justify-content: center;
  }

  .action-button {
    padding: 6px 12px;
    font-size: 0.9em;
  }
`;

export default function Clients() {
  const { clientData, totalPages, isLoading, error, fetchData } =
    useGetClientData();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [inProgressSearchTerm, setInProgressSearchTerm] = useState<string>("");

  useEffect(() => {
    fetchData(pageNumber, searchTerm);
  }, [fetchData, pageNumber, searchTerm]);

  const clientTableData = clientData ?? [];

  const capitalizeWords = (input_str: string | null) => {
    if (input_str) {
      return input_str
        .toLocaleLowerCase()
        .replace(/\b\w/g, (char: string) => char.toUpperCase());
    }
    return "N/A";
  };

  return (
    <StyledContainer fluid="md">
      <Row className="justify-content-center" style={{marginTop: "24px"}}>
        <Row className="justify-content-center">
          <Col className="col-auto">
            <h2>Clients</h2>
          </Col>
        </Row>
      </Row>
      <ButtonToolbar className="justify-content-between w-100 p-0">
        <DataListSearch
          setSearchTerm={setSearchTerm}
          setPageNumber={setPageNumber}
          inProgressSearchTerm={inProgressSearchTerm}
          setInProgressSearchTerm={setInProgressSearchTerm}
          page={"Clients"}
        />
        <DataListPagination
          totalPages={totalPages}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
        />
      </ButtonToolbar>
      <ClientTable>
        <thead>
          <tr>
            <th>Client Name</th>
            <th>Address</th>
            <th>City</th>
            <th>State</th>
            <th>Zip</th>
            <th>Trust Name</th>
            <th>Trust Date</th>
            <th>Edit</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <th>Loading... </th>
            </tr>
          ) : clientTableData.length > 0 ? (
            clientTableData.map((data: TClientData) => (
              <tr key={data.id}>
                <th>{capitalizeWords(data.name)}</th>
                <th>{capitalizeWords(data.address)}</th>
                <th>{capitalizeWords(data.city)}</th>
                <th>{data.state}</th>
                <th>{data.zip}</th>
                <th>{capitalizeWords(data.trustName)}</th>
                <th>{data.trustDate}</th>
                <th>
                  <Button href={UrlPaths.EditClient + data.id}>Edit</Button>
                </th>
              </tr>
            ))
          ) : (
            <tr>
              <th>No data found</th>
            </tr>
          )}
        </tbody>
      </ClientTable>
      <ButtonToolbar className="w-100 p-0">
        <Button className="m-3" href={UrlPaths.NewClient}>
          + New client
        </Button>
      </ButtonToolbar>
      {error != null ? <div>The error is: {error.message}</div> : null}
    </StyledContainer>
  );
}
