import { Button, Form, Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useEffect, useState } from "react";

import usePostBatchTemplatesData from "../../hooks/templateHooks/usePostBatchTemplates";

export type TTemplateData = {
  value: string;
  name: string;
  isSelected: boolean;
  isDisabled: boolean;
}[];

export default function GenerateBatchTemplateModal({
  handleClose,
  trustId,
  trustName,
  trustType,
}: {
  handleClose: () => void;
  trustId: string;
  trustName: string;
  trustType: string;
}) {
  const [formData, setFormData] = useState([
    {
      id: 1,
      value: "vialOfLife",
      name: "Vial of Life",
      isSelected: trustType !== "TA",
      isDisabled: false,
    },
    {
      id: 2,
      value: "binderBasics",
      name: "Binder Basics",
      isSelected: trustType !== "TA",
      isDisabled: false,
    },
    {
      id: 3,
      value: "averyLabels",
      name: "Avery Labels",
      isSelected: true,
      isDisabled: false,
    },
    {
      id: 4,
      value: "trustTransferDeed",
      name: "Trust Transfer Deed",
      isSelected: true,
      isDisabled: false,
    },
    {
      id: 5,
      value: "trustCertificate",
      name: "Trust Certificate",
      isSelected: true,
      isDisabled: false,
    },
    {
      id: 6,
      value: "loveLetter",
      name: "Love Letter",
      isSelected: true,
      isDisabled: false,
    },
    {
      id: 7,
      value: "cpt",
      name: "CPT",
      isSelected: trustType === "CPT",
      isDisabled: trustType !== "CPT",
    },
    {
      id: 8,
      value: "spt",
      name: "SPT",
      isSelected: trustType === "SPT",
      isDisabled: trustType !== "SPT",
    },
    {
      id: 9,
      value: "ta",
      name: "TA",
      isSelected: trustType === "TA",
      isDisabled: trustType !== "TA",
    },
  ]);

  const [deactivate, setDeactivate] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  const { fetchBatchTemplates, isLoadingBatchTemplates } =
    usePostBatchTemplatesData();

  const isSubmitDeactive = (formData: TTemplateData) => {
    if (Object.values(formData).every((item) => item.isSelected === false)) {
      setDeactivate(true);
    } else {
      setDeactivate(false);
    }
  };

  const handleSelectAll = (event: any) => {
    const checked = event.target.checked;
    setSelectAll(checked);
    setFormData(
      formData.map((item) => {
        if (!item.isDisabled) {
          return { ...item, isSelected: checked };
        } else {
          return { ...item, isSelected: false };
        }
      })
    );
  };

  useEffect(() => {
    const isAllSelected = (formData: TTemplateData) => {
      if (!selectAll) {
        const totalSelectionCount =
          formData.length - formData.filter((j) => j.isDisabled).length;
        if (
          formData.filter((i) => {
            return i.isSelected;
          }).length === totalSelectionCount
        ) {
          setSelectAll(true);
        }
      }
    };

    isSubmitDeactive(formData);
    isAllSelected(formData);
  }, [formData, selectAll]);

  const handleSubmit = () => {
    fetchBatchTemplates(formData, trustId, trustName);

    if (!isLoadingBatchTemplates) handleClose();
  };

  const handleCheckboxChange = (id: any) => {
    const updatedItems = formData.map((item) =>
      item.id === id ? { ...item, isSelected: !item.isSelected } : item
    );

    setFormData(updatedItems);
    setSelectAll(updatedItems.every((item) => item.isSelected));
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Generate Templates</Modal.Title>
      </Modal.Header>
      {isLoadingBatchTemplates ? (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner
            animation="border"
            role="status"
            variant="primary"
            style={{ margin: "20px 0" }}
          ></Spinner>
        </div>
      ) : (
        <>
          <Modal.Body>
            <div>
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  label="Select All"
                  checked={selectAll}
                  onChange={handleSelectAll}
                />
                {formData.map((item) => (
                  <Form.Check
                    style={{ marginLeft: "24px" }}
                    type="checkbox"
                    label={item.name}
                    checked={item.isSelected}
                    value={item.value}
                    disabled={item.isDisabled}
                    onChange={() => handleCheckboxChange(item.id)}
                  />
                ))}
              </Form.Group>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              variant="primary"
              disabled={deactivate}
              onClick={handleSubmit}
            >
              Generate
            </Button>
          </Modal.Footer>
        </>
      )}
    </>
  );
}
