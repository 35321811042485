import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import usePostNewClient from "../../hooks/clientHooks/usePostNewClient";
import { TClientData } from "../../utilities/clientTypes";
import ClientForm from "./ClientForm";
import { Button } from "react-bootstrap";
import { UrlPaths } from "../../utilities/types";

export default function NewClient() {
  const { submitClient, isLoading, error } = usePostNewClient();

  if (error != null) {
    console.error("Error in NewClient is: ", error);
  }

  const sendNewClientData = (clientData: TClientData) => {
    submitClient(clientData);
  };
  return (
    <>
      <Container fluid>
        <Row className="m-3 justify-content-center border">
          <Row className="justify-content-center">
            <Col className="col-auto">
              <h3>New Client Information</h3>
            </Col>
          </Row>
          <ClientForm
            clientData={null} // not editing existing data so pass null
            isLoading={isLoading}
            error={error}
            apiFunction={sendNewClientData}
          />
        </Row>
        <Button href={UrlPaths.Clients}>Back</Button>
      </Container>
      {error != null ? <div>The error is: {error.message}</div> : null}
    </>
  );
}
