import React, { useState, useEffect } from 'react';
import { InputGroup, Form, Dropdown } from "react-bootstrap";
import useGetClientContactData from "../../hooks/messageHooks/useGetClientContactData"

type SearchSettings = {
  createThread: Function,
  page: string,
}

export default function DataListSearch({
  createThread,
  page,
}: SearchSettings) {
  const [showDropdown, setShowDropdown] = useState(false);
  const { clientContactData, totalPages, isLoadingClientContacts, fetchData } = useGetClientContactData();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [inProgressSearchTerm, setInProgressSearchTerm] = useState<string>("");

  useEffect(() => {
    fetchData(pageNumber, searchTerm);
  }, [fetchData, pageNumber, searchTerm]);

  const updateSearchTerm = (value: string) => {
    setInProgressSearchTerm(value)
    if (inProgressSearchTerm === "") {
        setSearchTerm(null);
        setPageNumber(1);
    } else {
        setSearchTerm(inProgressSearchTerm);
        setPageNumber(1);
      }
      setShowDropdown(true);
  };

  const handlePageChange = (newPage: number, event: React.MouseEvent) => {
    event.stopPropagation();
    if (newPage >= 1 && newPage <= totalPages) {
        setPageNumber(newPage);
    }
    setShowDropdown(true);
  };

  const capitalizeWords = (input_str: string | null) => {
    if (input_str) {
      return input_str.toLocaleLowerCase().replace(/\b\w/g, (char: string) => char.toUpperCase());
    }
    return 'N/A'
  };

  return (
    <Dropdown show={showDropdown} onToggle={(isOpen) => setShowDropdown(isOpen)}>
      <Dropdown.Toggle as={InputGroup} style={{ paddingBottom: '10px', paddingTop: '10px'}}>
        <Form.Control
          id="clientContactDataSearch"
          type="text"
          placeholder={`Search ${page}...`}
          aria-label="search clients Dropdown"
          aria-describedby="client dropdown Search"
          value={inProgressSearchTerm}
          onChange={({ target: { value } }) => updateSearchTerm(value)}
          onClick={() => setShowDropdown(true)}
        />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={(e) => handlePageChange(pageNumber - 1, e)} disabled={pageNumber === 1}>
          Previous Page
        </Dropdown.Item>
        {isLoadingClientContacts ? (<Dropdown.Item>Loading...</Dropdown.Item>) : (
          clientContactData?.map((client: any, index: number) => (
            <Dropdown.Item key={index} onClick={() => createThread(client)}>{capitalizeWords(client.fullname)}: {client.phoneNumber}</Dropdown.Item>
          ))
        )}
        <Dropdown.Item onClick={(e) => handlePageChange(pageNumber + 1, e)} disabled={pageNumber === totalPages}>
          Next Page
        </Dropdown.Item>
        <Dropdown.Item>
          Page {pageNumber} of {totalPages}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}