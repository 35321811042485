import { Redirect, Route, Switch } from "wouter";
import Dashboard from "../dashboard/Dashboard";
import { UrlPaths } from "../../utilities/types";
import PagePlaceholder from "../common/PagePlaceholder";
import Clients from "../clients/Clients";
import NewClient from "../clients/NewClient";
import EditClient from "../clients/EditClient";
import ViewClient from "../clients/ViewClient";
import Templates from "../templates/Templates";
import Messages from "../massages/Messages";
import Trusts from "../trusts/Trusts";
import NewTrust from "../trusts/NewTrust";
import EditTrust from "../trusts/EditTrust";
import ViewTrust from "../trusts/ViewTrust";

export default function Viewport() {
  return (
    <div className="viewport">
      <Switch>
        <Route path={UrlPaths.Dashboard}>
          <Dashboard />
        </Route>
        <Route path={UrlPaths.Notifications}>
          <h2>Notifications</h2>
          <PagePlaceholder />
        </Route>
        <Route path={UrlPaths.Trusts}>
          <Trusts />
        </Route>
        <Route path={UrlPaths.NewTrust}>
          <NewTrust />
        </Route>
        <Route path={UrlPaths.EditTrust + ":id"}>
          <EditTrust />
        </Route>
        <Route path={UrlPaths.ViewTrust + ":id"}>
          <ViewTrust />
        </Route>
        <Route path={UrlPaths.Clients}>
          <Clients />
        </Route>
        <Route path={UrlPaths.NewClient}>
          <NewClient />
        </Route>
        <Route path={UrlPaths.EditClient + ":id"}>
          <EditClient />
        </Route>
        <Route path={UrlPaths.ViewClient + ":id"}>
          <ViewClient />
        </Route>
        <Route path={UrlPaths.Appointments}>
          <h2 style={{textAlign: "center", margin:" 24px 0"}}>Appointments</h2>
          <PagePlaceholder />
        </Route>
        <Route path={UrlPaths.Calendar}>
          <h2 style={{textAlign: "center", margin:" 24px 0"}}>Calendar</h2>
          <PagePlaceholder />
        </Route>
        <Route path={UrlPaths.Messages}>
          <h2 style={{textAlign: "center", margin:" 24px 0"}}>Messages</h2>
          <Messages />
        </Route>
        <Route path={UrlPaths.Payments}>
          <h2 style={{textAlign: "center", margin:" 24px 0"}}>Payments</h2>
          <PagePlaceholder />
        </Route>
        <Route path={UrlPaths.Templates}>
          <Templates />
        </Route>
        <Route>
          <Redirect to="/notfound" />
        </Route>
      </Switch>
    </div>
  );
}
