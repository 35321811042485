import { useState } from 'react';
import styled from 'styled-components';
import { Container, Row } from 'react-bootstrap';
import MessageSidebar from './MessageSidebar';
import ChatBox from './ChatBox'

const ChatContainer = styled(Container)`
    border: 2px solid #e0e0e0;
    border-radius: 8px;
    background-color: #ffffff;
    padding: 0;
    margin: 5px;
    overflow: hidden;
`;

const ChatRow = styled(Row)`
    margin: 0;
`;


export const Header = styled.div`
  padding: 1rem;
  border-bottom: 1px solid #dee2e6;
`;

export type TMessagesData = {
    id: number,
    clientId: number,
    threadId: number,
    sender: string,
    text: string,
    timestamp: string,
}


export type TThreadMessages = {
    id: number,
    sender: string,
    text: string,
    clientId: number,
    threadId: number,
    unread: number
}

export default function Messages() {
  const [selectedClientData, setSelectedClientData] = useState<any | null>(null)

  return (
    <ChatContainer fluid>
        <ChatRow>
          <MessageSidebar setClientId={setSelectedClientData} />
          {selectedClientData !== null ?  <ChatBox selectedClientData={selectedClientData} /> : null}
        </ChatRow>
    </ChatContainer>
  );
}
