import { Button, Row, Container } from "react-bootstrap";
import { UrlPaths } from "../../utilities/types";
import { TTrustsData } from "../../utilities/trustTypes";
import TrustForm from "./TrustForm";
import { useState } from "react";
import styled from "styled-components";

const StyledActionButtons = styled.div`
  margin: 18px 8px 18px 0;

  .btn:last-of-type {
    margin-left: 8px;
  }
`;

export default function TrustInfoTab({
  trustID,
  trustsData,
  error,
}: {
  trustID: string;
  trustsData: TTrustsData | null;
  error: any;
}) {
  const [isReadOnly, setIsReadOnly] = useState(true);

  const toggleEditMode = () => setIsReadOnly((prev) => !prev);

  return (
    <Container fluid>
      <Row>
        <TrustForm
          trustsData={trustsData}
          isLoading={false}
          error={error}
          readOnly={isReadOnly}
        />
      </Row>
      <StyledActionButtons>
        <Button href={UrlPaths.EditTrust + trustID}>Edit</Button>
        <Button onClick={toggleEditMode} href={UrlPaths.Trusts}>
          Back
        </Button>
      </StyledActionButtons>
    </Container>
  );
}
